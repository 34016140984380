/* eslint-disable */
import React, { useState } from 'react';
import { Router } from 'react-router';
import { useHistory, BrowserRouter, Route, Redirect } from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  Checkbox,
  notification,
  Row,
  Modal,
  Col,
  Typography,
} from 'antd';
import {
  UserOutlined,
  FrownOutlined,
  SmileOutlined,
  LockOutlined,
} from '@ant-design/icons';
import Logo from './components/aim_final_logo.png';
import './components/style.css';
// import 'antd/dist/antd.css';
// import '../index.css';
import Axios from 'axios';
import InternalTools from './pages/internalTools';

function Login(props: any) {
  const [username, setusername] = useState<string>();
  const [password, setPass] = useState<string>();
  const [userErr, setUserErr] = useState<boolean>(false);
  const [passErr, setPassErr] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const API_URL = process.env.REACT_APP_API_URL;
  const history = useHistory();

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.name === 'user') {
      setusername(e.target.value);
    } else {
      setPass(e.target.value);
    }
  }

  const login = (event: React.FormEvent) => {
    event.preventDefault();
    const element = document.getElementById('checkbox') as HTMLInputElement;
    const user = document.getElementById('user') as HTMLInputElement;
    const pass = document.getElementById('pass') as HTMLInputElement;

    if (user.value === '' && pass.value === '') {
      setUserErr(false);
    } else {
      if (pass.value === '') {
        setUserErr(false);
        setPassErr(false);
      } else {
        console.log(`Username: ${username} Remember Me? ${element.checked}`);
        let thrown = false;
        Axios.post(`${API_URL}/user/login`, { username, password })
          .then((res) => {
            if (res.status == 200) {
              localStorage.setItem('logged', 'true');
              ///>
              /* eslint-disable-line */
              console.log('LOGGED IN');
              props.logged();
              history.push('/tools');
              <></>;
            }
            if (res.status == 400) {
              setPassErr(true);
              setUserErr(false);
            }

            if (res.status == 500) {
              thrown = true;
              setUserErr(true);
              setPassErr(false);
            }
          })
          .then((data) => {
            console.log(JSON.stringify(data));
          })
          .catch((error) => {
            if (error.response != null) {
              if (error.response.status == '400') {
                notification.open({
                  message: 'Error',
                  description: 'Invalid password.',
                  icon: <FrownOutlined style={{ color: '#ff0000' }} />,
                });
              } else if (error.response.status == '500') {
                notification.open({
                  message: 'Error',
                  description: "Username doesn't exist.",
                  icon: <FrownOutlined style={{ color: '#ff0000' }} />,
                });
              } else {
                notification.open({
                  message: 'Error',
                  description: 'Error, please try again.',
                  icon: <FrownOutlined style={{ color: '#ff0000' }} />,
                });
              }
            } else {
              notification.open({
                message: 'Error',
                description: 'Error, please try again.',
                icon: <FrownOutlined style={{ color: '#ff0000' }} />,
              });
            }
          });
      }
    }
  };
  const { Title } = Typography;
  return (
    <Row align="middle" className="row">
      <Col lg={12} sm={24} md={24} xs={24}>
        <img src={Logo} alt="Logo" className="logo-login" />
      </Col>

      <Col lg={12} sm={24} md={24} xs={24} className="divider">
        <Title id="title" level={2}>
          Welcome Back!
        </Title>

        <Form
          onSubmitCapture={login}
          className="form"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
        >
          <Form.Item
            justify-content="center"
            align-items="middle"
            name="username"
            rules={[{ required: true, message: 'Username Cannot be Empty' }]}
            validateStatus={userErr ? 'error' : ''}
            help={userErr ? 'Incorrect Username' : null}
            className="user"
          >
            <Input
              name="user"
              value={username}
              onChange={onChange}
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
              id="user"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Password Cannot be Empty!' }]}
            validateStatus={passErr ? 'error' : ''}
            help={passErr ? 'Incorrect Password' : null}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              value={password}
              onChange={onChange}
              id="pass"
            />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked">
            <Checkbox id="checkbox">Remember Me</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              shape="round"
              size="large"
              className="button"
            >
              Sign In
            </Button>
            Or <a onClick={() => setVisible(true)}>register now!</a>
          </Form.Item>
        </Form>
      </Col>
      <Modal onCancel={() => setVisible(false)} visible={visible} footer={null}>
        <h1 className="home__modal__header"> Register</h1>
        <Form
          form={form}
          onFinish={(values) => {
            console.log(values);
            setLoading(true);
            Axios.post(`${API_URL}/user/`, values)
              .then((res) => {
                if (res.status === 200) {
                  setVisible(false);
                  setLoading(false);

                  notification.open({
                    message: 'Success',
                    description: 'User successfully registered',
                    icon: <SmileOutlined style={{ color: '#00ff00' }} />,
                  });
                  form.resetFields();
                } else {
                  setLoading(false);
                  setVisible(false);
                  notification.open({
                    message: 'Error',
                    description: 'There has been an Error',
                    icon: <FrownOutlined style={{ color: '#ff0000' }} />,
                  });
                  form.resetFields();
                }
              })
              .catch((error) => {
                setLoading(false);
                setVisible(false);
                notification.open({
                  message: 'Error',
                  description: 'There has been an Error',
                  icon: <FrownOutlined style={{ color: '#ff0000' }} />,
                });
                form.resetFields();
              });
          }}
        >
          <Form.Item
            className="home__modal__input"
            name="username"
            label="Username"
            rules={[{ required: true, message: 'Please input your Username!' }]}
          >
            <Input
              placeholder="Please input your Username"
              prefix={<UserOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item
            label="Password"
            className="home__modal__input"
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input.Password
              placeholder="Please input your Password..."
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
            />
          </Form.Item>

          <Form.Item>
            <Button
              className="home__modal__button"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
}

export default Login;
