import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import {
  Cascader,
  Row,
  List,
  Col,
  Input,
  Form,
  Button,
  Typography,
  Table,
  Space,
  Card,
  Tooltip,
  Modal,
  Pagination,
  Select,
  Popover
} from "antd";
import Loader from "react-loader-spinner";
// @ts-ignore
import {
  NotificationContainer,
  NotificationManager
  // @ts-ignore
} from "react-notifications";
import "react-notifications/lib/notifications.css";
// @ts-ignore
import readXlsxFile from "read-excel-file";
type ImportAnswersComponentProps = {
  surveys: any;
};
const ImportAnswers: React.FC<ImportAnswersComponentProps> = props => {
  var FileSaver = require("file-saver");
  const [surveys, setSurveys] = useState<any>([]);
  const [surveydd, setSurveydd] = useState<any>([]);
  const [selectedSurvey, setSelectedSurvey] = useState<any>();
  const [questions, setQuestions] = useState<any>([]);
  const API_URL = process.env.REACT_APP_API_URL;
  const [loadingtab, setLoadingtab] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState<any>();
  const [importedData, setImportedData] = useState<any>([]);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const reader = new FileReader();
  useEffect(() => {
    const fetchSurvey = async () => {
      setSurveys(props.surveys);
      if (
        props.surveys &&
        props.surveys.data &&
        props.surveys.data.length > 0
      ) {
        setSurveydd(
          props.surveys.data.map((survey: any) => {
            return { value: survey.id, label: survey.name };
          })
        );
      }
    };
    fetchSurvey();
  }, [props.surveys]);
  function handleSurveyChange(value: any) {
    const questionsTemp: any = [];
    surveys.data.map((survey: any) => {
      if (survey.id === value) {
        setSelectedSurvey(survey);
        if (survey.sections) {
          survey.sections.map((section: any) => {
            if (section && section.questions) {
              section.questions.map((question: any) => {
                if (question.isActive) {
                  questionsTemp.push({
                    value: question.id,
                    label: question.header
                  });
                }
              });
            }
          });
        }
      }
    });
    setQuestions(questionsTemp);
  }
  function handleQuestionChange(value: any) {
    if (selectedSurvey.sections) {
      selectedSurvey.sections.map((section: any) => {
        if (section && section.questions) {
          section.questions.map((question: any) => {
            if (question && question.id === value) {
              setSelectedQuestion(question);
            }
          });
        }
      });
    }
  }
  async function parseData(e: any) {
    setLoadingtab(true);
    let tempImportedData: any = [];
    await readXlsxFile(e.target.files[0]).then(async(rows: any) => {
      for (let i = 0; i < rows.length; i++) {
        let sessionId = rows[i][0];
        let answer_header = rows[i][1];
        let answer_value = rows[i][2];
        let questionId = selectedQuestion.id;
        let answerId = 0;
        if (selectedSurvey.sections) {
         let answersFetch= await selectedSurvey.sections.map(async(section: any) => {
            if (section && section.questions) {
             await section.questions.map((question: any) => {
                if (
                  question &&
                  question.answers &&
                  question.id === questionId
                ) {
                  question.answers.map((answer: any) => {
                    if (answer.header == answer_header) {
                      answerId = answer.id;
                    }
                  });
                }
              });
            }
          });
          Promise.all(answersFetch).then((res)=>{
            tempImportedData.push({
              respondent: sessionId,
              question: questionId,
              answer: answerId,
              answerValue: answer_value,
              survey: selectedSurvey.id
            });
          })
        }else{
          tempImportedData.push({
            respondent: sessionId,
            question: questionId,
            answer: answerId,
            answerValue: answer_value,
            survey: selectedSurvey.id
          });
        }
      }
    });
    console.log(tempImportedData);
    setImportedData(tempImportedData);
    setLoadingtab(false);
    setDisableSubmit(false);
  }
  async function importData() {
    try {
      await axios
        .post(API_URL + "/internaltool/import-responses", importedData)
        .then(response => {
          setLoadingtab(false);
          if (response.data.success) {
            NotificationManager.success(
              `Done updating ${response.data.count} rows!`,
              "Success !!",
              10000
            );
          } else {
            NotificationManager.error("Please try again", "Error !!", 3000);
          }
        });
    } catch (err) {
      setLoadingtab(false);
      NotificationManager.error("Please try again", "Error !!", 3000);
    }
  }
  return (
    <Form>
      <Row style={{ width: "100%", marginBottom: 15 }}>
        <Col span={8}>Survey:</Col>
        <Col span={8}>
        <Select
            showSearch
            dropdownMatchSelectWidth={false}
            allowClear
            style={{ width: "100%" }}
            options={surveydd}
            placeholder="Please Select Survey"
            onChange={handleSurveyChange}
            filterOption={(input, option) => {return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}}
            optionFilterProp="children"
          ></Select>
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Col span={8}>Question:</Col>
        <Col span={8}>
          <Select
            showSearch
            defaultValue={"none"}
            dropdownMatchSelectWidth={false}
            allowClear
            style={{ width: "100%" }}
            options={questions}
            placeholder="Please Select Question"
            onChange={handleQuestionChange}
            filterOption={(input, option) => {return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}}
            optionFilterProp="children"
          ></Select>
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Col span={8}>
          Upload Excel In format Session ID, Answer Header, Answer Value
        </Col>
        <Col span={8}>
          <Input
            style={{ width: "100%" }}
            type="file"
            onChange={e => {
              parseData(e);
            }}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Button
          disabled={loadingtab || disableSubmit}
          style={{ float: "right", marginBottom: "-3%" }}
          onClick={() => {
            importData();
          }}
        >
          Submit
        </Button>
      </Row>
      {loadingtab && (
        <Loader
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)"
          }}
          type="BallTriangle"
          color="#00BFFF"
          height="120"
          width="80"
        />
      )}
      <NotificationContainer />
    </Form>
  );
};
export default ImportAnswers;
