import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import {
  Cascader,
  Row,
  List,
  Col,
  Input,
  Form,
  Button,
  Popconfirm,
  Typography,
  Space,
  Card,
  Tooltip,
  Modal,
  Pagination,
  Select,
  Popover,
  Table,
  InputNumber,
  Divider,
  message,
} from "antd";
import Loader from "react-loader-spinner";
// @ts-ignore
import {
  NotificationContainer,
  NotificationManager,
  // @ts-ignore
} from "react-notifications";
import "react-notifications/lib/notifications.css";
// @ts-ignore
import readXlsxFile from "read-excel-file";
import DataTable from "react-data-table-component";
import "./vouchers.scss";
import {
  CASHCALL_LOGIN_ID,
  CASHCALL_LOGIN_PASSWORD,
  CASHCALL_SECRET_KEY,
} from "../../config/app.config";
const { Option } = Select;

type VouchersComponentProps = {
  // surveys: any;
};
const Vouchers: React.FC<VouchersComponentProps> = (props) => {
  const [importedData, setImportedData] = useState<any>([]);
  const [loadingtab, setLoadingtab] = useState(false);
  const [vouchers, setVouchers] = useState<any>();
  const API_URL = process.env.REACT_APP_API_URL;
  const [form] = Form.useForm();
  const [formVoucher] = Form.useForm();
  const [priceList, setPriceList] = useState<any>([]);
  const [textVouchers, setTextVouchers] = useState<any>();
  const [currentBalance, setCurrentBalance] = useState<any>();
  const [selectedVoucherBrand, setSelectedVoucherBrand] = useState<
    string | null
  >(null);
  const CryptoJS = require("crypto-js");

  const columns = [
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      sorter: (a: any, b: any) => a.brand.length - b.brand.length,
    },
    {
      title: "Remaining",
      dataIndex: "remaining",
      key: "remaining",
      sorter: (a: any, b: any) => a.remaining - b.remaining,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const currentDate = new Date();
      const plainText =
        currentDate.toISOString().split("T")[0] +
        CASHCALL_LOGIN_ID +
        CASHCALL_LOGIN_PASSWORD +
        CASHCALL_SECRET_KEY;
      const ecryptedText = CryptoJS.MD5(plainText).toString();
      const balanceBody = {
        channel_code: "STS",
        channel_key: "STS",
        client_version: "V1",
        login_id: CASHCALL_LOGIN_ID,
        password: CASHCALL_LOGIN_PASSWORD,
        hash_code: ecryptedText,
        payment_network_code: "CACPN",
      };
      const response = await axios.get(`${API_URL}/internaltool/getVouchers`);
      const cashcallResponse = await axios.post(
        "https://app1.cashcall.com.eg:9090/mfsexternaltransactions/STSBalanceEnquiry",
        balanceBody
      );
      setCurrentBalance(
        Math.round(cashcallResponse?.data?.balance_result[0]?.current_balance)
      );
      setVouchers(response.data);
    };
    fetchData();
  }, [currentBalance]);

  async function parseData(e: any) {
    let tempImportedData: any = await e.target.files[0].text();
    setImportedData(tempImportedData.trim().split(/\r?\n/));
  }

  async function uploadVouchers(values: any) {
    console.log("values", values);
    setLoadingtab(true);
    let vocuhersList: any = [];
    console.log(importedData.length);
    for (let i = 0; i < importedData.length; i++) {
      console.log("farah", importedData[i]);
      console.log("Hossam", values);
      if (importedData[i] !== "") {
        vocuhersList.push({
          brand: values.brand,
          voucher: importedData[i].trim().split("   ")[0],
          price: values.price,
          location: values.location,
        });
      }
    }
    try {
      await axios
        .post(API_URL + "/internaltool/upload-vouchers", vocuhersList)
        .then((response) => {
          NotificationManager.success(
            `${response.data.count} Vouchers inserted`,
            "Success"
          );
        })
        .catch((err) => {
          NotificationManager.error("Please Try again", "Error !!");
        });
    } catch (err) {
      NotificationManager.error("Please Try again", "Error !!");
    }
    const response = await axios.get(`${API_URL}/internaltool/getVouchers`);
    setVouchers(response.data);
    form.resetFields();
    setLoadingtab(false);
  }

  async function updatePrice(value: string) {
    console.log("value", value);
    switch (value) {
      case "EE": setPriceList([10.5, 15,15.5, 17.5, 12]); break;
      case "WE": setPriceList([6, 8, 10.5,11.5,17.5]); break;
      case "OE": setPriceList([10,12, 15,20]);
        break;
      case "VE":
        setPriceList([5, 10, 15, 25, 50, 100]);
        break;
      default:
        break;
    }
    updateText();
  }

  async function fetchVouchers() {
    const values = formVoucher.getFieldsValue();
    console.log(values,"****");
    
    axios
      .post(`${API_URL}/internaltool/requestVouchers`, { values })
      .then((res) => {
        console.log(res);
        formVoucher.resetFields();
        setLoadingtab(false);
        message.success(
          "Your request has been submitted. Please wait for the approval"
        );
      })
      .catch((err) => {
        message.error("An error has occured please try again");
      });
  }
  async function updateText() {
    setTextVouchers(
      `Are You Sure you want to request ${
        formVoucher.getFieldsValue()?.amount
      } ${formVoucher.getFieldsValue()?.cashCallBrand} vouchers worth ${
        formVoucher.getFieldsValue()?.price
      }EGP each?`
    );
  }

  return (
    <Form>
      <Table
        columns={columns}
        dataSource={vouchers}
        rowClassName={(record, index) =>
          record.remaining < 55 ? "red" : "green"
        }
      />
      {currentBalance ? (
        <h1>Current Cashcall Balance: {currentBalance} EGP</h1>
      ) : (
        ""
      )}
      <Row gutter={16}>
        <Col span={11}>
          <Divider style={{ width: "40%" }} plain type="horizontal">
            <h1>Upload Vouchers</h1>
          </Divider>
          <Form
            form={form}
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={uploadVouchers}
          >
            <Form.Item
              label="Brand"
              name="brand"
              rules={[{ required: true, message: "Please select a brand!" }]}
            >
              <Select
                placeholder="Select a brand"
                onChange={(val: any, option: any) => {
                  if (val == "Costa Coffee KSA" || val == "Costa Coffee Kuwait") {
                    form.setFieldsValue({
                      ...form.getFieldsValue(),
                      price: "0",
                    });
                  }
                  setSelectedVoucherBrand(val);
                }}
                allowClear
              >
                <Option value="vodafone كارت شحن">vodafone كارت شحن</Option>
                <Option value="etisalat كارت شحن">etisalat كارت شحن</Option>
                <Option value="orange كارت شحن">orange كارت شحن</Option>
                <Option value="we كارت شحن">we كارت شحن</Option>
                <Option value="كارت فودافون فكه">كارت فودافون فكه</Option>
                <Option value="كارت فودافون">كارت فودافون</Option>
                <Option value="كارت سيا بقيمة ٢٠ جنيه">
                  كارت سيا بقيمة ٢٠ جنيه
                </Option>
                <Option value="Costa Coffee KSA">Costa Coffee KSA</Option>
                <Option value="Costa Coffee Kuwait">Costa Coffee Kuwait</Option>
                <Option value="Breadfast Gift Card">Breadfast Gift Card</Option>
                <Option value="Amazon Gift Card">Amazon Gift Card</Option>
                <Option value="بروموكود Rabbit">بروموكود Rabbit</Option>
                <Option value="Noon KSA">Noon KSA</Option>

              </Select>
            </Form.Item>

            <Form.Item
              label="Price"
              name="price"
              rules={[
                {
                  // required: true,
                  // message: 'Please select voucher prices',
                  validator: async (_, value) => {
                    if (!value && selectedVoucherBrand != "Costa Coffee") {
                      console.log("validate error");
                      throw new Error("Please select a price!");
                    }
                    Promise.resolve(true);
                  },
                },

                // { required: true, message: "Please select a price!" },
              ]}
            >
              <Select
                disabled={selectedVoucherBrand == "Costa Coffee KSA" || selectedVoucherBrand == "Costa Coffee Kuwait"  }
                placeholder={
                  "Select a price"
                  // selectedVoucherBrand == "Costa Coffee" ? "none" : "Select a price"
                }
              >
                <Option value="0">0</Option>
                <Option value="2.5">2.5</Option>
                <Option value="4.25">4.25</Option>
                <Option value="6">6</Option>
                <Option value="7">7</Option>
                <Option value="10">10</Option>
                <Option value="13">13</Option>
                <Option value="20">20</Option>
                <Option value="25">25</Option>
                <Option value="100">100</Option>
                <Option value="250">250</Option>
                <Option value="500">500</Option>



              </Select>
            </Form.Item>

            <Form.Item
              label="Location"
              name="location"
              rules={[{ required: true, message: "Please select a location!" }]}
            >
              <Select placeholder="Select a location">
                <Option value="egypt">Egypt</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Vouchers File"
              name="vouchers"
              rules={[
                { required: true, message: "Please upload the vouchers file!" },
              ]}
            >
              <Input
                style={{ width: "100%" }}
                type="file"
                onChange={(e) => {
                  parseData(e);
                }}
              />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit" disabled={loadingtab}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={11}>
          <Divider style={{ width: "40%" }} plain type="horizontal">
            <h1>Cashcall Vouchers</h1>
          </Divider>
          <Form
            form={formVoucher}
            labelAlign="left"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={fetchVouchers}
          >
            <Form.Item
              label="Brand"
              name="cashCallBrand"
              rules={[{ required: true, message: "Please select a brand!" }]}
            >
              <Select
                placeholder="Select a brand"
                allowClear
                onChange={updatePrice}
              >
                <Option value="VE">Vodafone</Option>
                <Option value="WE">WE</Option>
                <Option value="EE">Etisalat</Option>
                <Option value="OE">Orange</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Price"
              name="price"
              rules={[{ required: true, message: "Please select a price!" }]}
            >
              <Select placeholder="Select a price" onChange={updateText}>
                {priceList.map((price: any) => {
                  return <Option value={price}>{price}</Option>;
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label="Amount"
              name="amount"
              rules={[
                {
                  required: true,
                  message:
                    "Please enter the number of vouchers you want to request!",
                },
              ]}
            >
              <InputNumber onChange={updateText} style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please insert your email!" }]}
            >
              <Input
                onChange={() => {
                  console.log(formVoucher.getFieldsValue());
                }}
                style={{ width: "100%" }}
              />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Popconfirm
                placement="topLeft"
                title={textVouchers}
                onConfirm={fetchVouchers}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" htmlType="submit" disabled={loadingtab}>
                  Submit
                </Button>
              </Popconfirm>
            </Form.Item>
          </Form>
        </Col>
      </Row>

      {loadingtab && (
        <Loader
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
          type="BallTriangle"
          color="#00BFFF"
          height="120"
          width="80"
        />
      )}
      <NotificationContainer />
    </Form>
  );
};
export default Vouchers;
