import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Layout, Menu, Breadcrumb } from 'antd';

import {
  SolutionOutlined,
  ProfileOutlined,
  FormOutlined,
  HomeOutlined,
  LogoutOutlined,
  FileSearchOutlined,
  KeyOutlined,
  QuestionOutlined,
  UserOutlined,
  ContainerOutlined,
} from '@ant-design/icons';

import 'antd/dist/antd.css';
import './layout.scss';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

interface AppLayoutProps {
  child?: React.ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = (props) => {
  const [collapsed, setCollapsed] = useState(true);

  const { child } = props;
  function onCollapse(collapsed: boolean) {
    console.log(collapsed);
    setCollapsed(!collapsed);
  }


  function signOut() {
    localStorage.setItem('logged', 'false');
    localStorage.setItem('role', '');
    localStorage.setItem('permissions', JSON.stringify({}));
    localStorage.setItem('token', '');
    localStorage.setItem('userId', '');
    localStorage.setItem('username', '');
  }
  return (
    <Layout className="container">
      <Header className="header">
        <a className="logo" href="/">
          <img alt="AIM" src="/images/aim.svg" />
        </a>
        <Menu mode="horizontal" style={{ height: '100%', borderRight: 0 }}>
          <SubMenu
            title={
              <>
                <UserOutlined />
                {localStorage.getItem('username')}
              </>
            }
          >
            <Menu.Item key="1">
              <a href="/login" onClick={signOut} className="logout">
                <LogoutOutlined /> Log Out
              </a>
            </Menu.Item>
          </SubMenu>
          {/* <Menu.Item
              key="1"
              icon={<HomeOutlined />}
              onClick={() => {
                const link = document.createElement("a");
                link.href = "/";
                link.click();
              }}
            >
              Home
            </Menu.Item> */}

          {/* <Menu.Item
              key="2"
              icon={<ProfileOutlined />}
              onClick={() => {
                const link = document.createElement('a');
                link.href = '/audience-segments';
                link.click();
              }}
            >
              Audience Segments
            </Menu.Item> */}
          {/* <Menu.Item
              key="3"
              icon={<ContainerOutlined />}
              onClick={() => {
                const link = document.createElement("a");
                link.href = "/library";
                link.click();
              }}
            >
              Library
            </Menu.Item> */}
        </Menu>
      </Header>
      <Layout>
        <Sider
          width={200}
          className="site-layout-background side-bar"
          collapsible
          collapsed={collapsed}
          onCollapse={() => {
            setCollapsed(!collapsed);
          }}
          theme="light"
        >

        </Sider>
        <Layout style={{ padding: '0 24px 24px' }}>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            {props.children}
          </Content>
        </Layout>
      </Layout>

    </Layout>
  );
};

export default AppLayout;
