import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import axios from 'axios';
import {
  Cascader,
  Row,
  List,
  Col,
  Input,
  Form,
  Button,
  Typography,
  Table,
  Space,
  Card,
  Tooltip,
  Modal,
  Pagination,
  Select,
  Popover,
  Divider,
  DatePicker,
} from 'antd';
import Loader from 'react-loader-spinner';
// @ts-ignore
import {
  NotificationContainer,
  NotificationManager,
  // @ts-ignore
} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
// @ts-ignore
import readXlsxFile from 'read-excel-file';
type ChangeSubmitDateComponentProps = {
  surveys: any;
};
const ChangeSubmitDate: React.FC<ChangeSubmitDateComponentProps> = (props) => {
  const [surveys, setSurveys] = useState<any>([]);
  const [surveydd, setSurveydd] = useState<any>([]);
  const [selectedSurvey, setSelectedSurvey] = useState<any>();
  const [selectedStatus, setSelectedStatus] = useState<any>();
  const [selectedForce, setSelectedForce] = useState<any>();
  const [questions, setQuestions] = useState<any>([]);
  const [respondentId, setRespondentId] = useState('');
  const [importedData, setImportedData] = useState<any>([]);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [loadingtab, setLoadingtab] = useState(false);
  const [finishedSurvey, setFinishedSurvey] = useState<any>();
  const [selectedDate, setSelectedDate] = useState<any>();

  const API_URL = process.env.REACT_APP_API_URL;
  var FileSaver = require('file-saver');

  useEffect(() => {
    const fetchSurvey = async () => {
      setSurveys(props.surveys);
      if (
        props.surveys &&
        props.surveys.data &&
        props.surveys.data.length > 0
      ) {
        setSurveydd(
          props.surveys.data.map((survey: any) => {
            return { value: survey.id, label: survey.name };
          })
        );
      }
    };
    fetchSurvey();
  }, [props.surveys]);

  function handleSurveyChange(value: any) {
    const questionsTemp: any = [];
    surveys.data.map((survey: any) => {
      if (survey.id === value) {
        setSelectedSurvey(survey);
      }
    });
    setQuestions(questionsTemp);
  }

  async function parseData(e: any) {
    console.log('Parse data');
    console.log(e.target.files);
    setLoadingtab(true);
    let tempImportedData: any = [];
    await readXlsxFile(e.target.files[0]).then((rows: any) => {
      console.log('raws');
      console.log(rows);
      for (let i = 0; i < rows.length; i++) {
        tempImportedData.push(rows[i][0]);
      }
    });
    setImportedData(tempImportedData);
    setLoadingtab(false);
    setDisableSubmit(false);
  }

  async function changeDate(value: any) {
    setSelectedDate(value);
  }

  async function changeResponseDate() {
    setLoadingtab(true);
    axios
      .post(`${API_URL}/internalTool/change-response-date`, {
        date: selectedDate.format('YYYY-MM-DD hh:mm:ss'),
        sessions: importedData,
        surveyId: selectedSurvey.id,
      })
      .then((response) => {
        setLoadingtab(false);
        NotificationManager.success(
          `${response.data.length} respondents modified successfully.`,
          'Success'
        );
      })
      .catch((err) => {
        console.log(err);
        setLoadingtab(false);
        NotificationManager.error('Please Try again', 'Error !!');
      });
  }

  return (
    <Form>
      <Row style={{ width: '100%', marginBottom: 50 }}>
        <Col xs={12}>
          <Divider style={{ width: '50%' }} plain type="horizontal">
            <h1>Change submit date:</h1>
          </Divider>
          <Row style={{ width: '100%', marginBottom: 20 }}>
            <Col span={4}>Survey:</Col>
            <Col span={8}>
            <Select
            showSearch
            dropdownMatchSelectWidth={false}
            allowClear
            style={{ width: "100%" }}
            options={surveydd}
            placeholder="Please Select Survey"
            onChange={handleSurveyChange}
            filterOption={(input, option) => {return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}}
            optionFilterProp="children"
          ></Select>
            </Col>
          </Row>
          <Row style={{ marginBottom: 15 }}>
            <Col span={4}>Upload Respondents:</Col>
            <Col>
              <Input
                style={{ width: '100%' }}
                type="file"
                onChange={(e) => {
                  parseData(e);
                }}
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: 15 }}>
            <Col span={4}>Date:</Col>
            <Col>
              <Space direction="vertical" size={12}>
                <DatePicker onChange={changeDate} />
              </Space>
            </Col>
          </Row>

          <Row style={{ marginBottom: 15 }}>
            <Button
              disabled={loadingtab || disableSubmit}
              style={{ float: 'right', marginBottom: '-3%' }}
              onClick={() => {
                changeResponseDate();
              }}
            >
              Submit
            </Button>
          </Row>
        </Col>
      </Row>

      <NotificationContainer />
    </Form>
  );
};

export default ChangeSubmitDate;
