import React, { useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import {
  Cascader,
  Row,
  Col,
  Form,
  Button,
  Select,
  DatePicker,
  Space,
  Switch,
  Input,
  message
} from "antd";
import Loader from "react-loader-spinner";
import {
  NotificationContainer,
  NotificationManager
  // @ts-ignore
} from "react-notifications";
import "react-notifications/lib/notifications.css";
type TabulationComponentProps = {
  surveys: any;
};
const Tabulation: React.FC<TabulationComponentProps> = props => {
  var FileSaver = require("file-saver");
  const [surveys, setSurveys] = useState<any>([]);
  const [surveydd, setSurveydd] = useState<any>([]);
  const [selectedSurvey, setSelectedSurvey] = useState<any>();
  const [questions, setQuestions] = useState<any>([]);
  const [selectedQuestion, setSelectedQuestion] = useState<any>();
  const [selectedFilter, setselectedFilter] = useState<any>();
  const [filterValues, setFilterValues] = useState<any>([]);
  const [selectedFilterValues, setselectedFilterValues] = useState<any>([]);
  const API_URL = process.env.REACT_APP_API_URL;
  const [loadingtab, setLoadingtab] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState<any>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<any>(null);
  const [aggregateTabulation, setAggregateTabulation] = useState<any>(true);
  const [rolling, setRolling] = useState<any>(null);
  const [selectedTimeInterval, setSelectedTimeInterval] = useState<any>(null);
  const [absoluteCount, setAbsoluteCount] = useState<any>(false);
  const [selectedSplits, setSelectedSplits] = useState<any>([]);
  const [selectedInterlockedSplit, setSelectedInterlockedSplit] = useState<
    any
  >();
  const [recievers, setRecievers] = useState<any>([]);
  const { RangePicker } = DatePicker;
  useEffect(() => {
    const fetchSurvey = async () => {
      setSurveys(props.surveys);
      if (
        props.surveys &&
        props.surveys.data &&
        props.surveys.data.length > 0
      ) {
        setSurveydd(
          props.surveys.data.map((survey: any) => {
            return { value: survey.id, label: survey.name };
          })
        );
      }
    };
    fetchSurvey();
  }, [props.surveys]);
  function handleSurveyChange(value: any) {
    const questionsTemp: any = [];
    surveys.data.map((survey: any) => {
      if (survey.id === value) {
        setSelectedSurvey(survey);
        if (survey.sections) {
          survey.sections.map((section: any) => {
            if (section && section.questions) {
              section.questions.map((question: any) => {
                if (question.isActive === true) {
                  questionsTemp.push({
                    value: question.header,
                    label: question.header
                  });
                }
              });
            }
          });
        }
      }
    });
    setQuestions(questionsTemp);
  }

  function handleQuestionChange(value: any) {
    if (selectedSurvey.sections) {
      selectedSurvey.sections.map((section: any) => {
        if (section && section.questions) {
          section.questions.map((question: any) => {
            if (question && question.id === value) {
              setSelectedQuestion(question);
            }
          });
        }
      });
    }
  }

  function handleSplitsChange(value: any) {
    setSelectedSplits(value);
  }
  function handleInterlockedQuestionChange(value: any) {
    console.log(value);
    setSelectedInterlockedSplit(value);
  }
  function handleFilterChange(value: any) {
    setselectedFilter(value);
    const answersTemp: any = [];
    if (selectedSurvey.sections) {
      selectedSurvey.sections.map((section: any) => {
        if (section && section.questions) {
          section.questions.map((question: any) => {
            if (question && question.header === value && question.answers) {
              question.answers.map((answer: any) => {
                answersTemp.push({ value: answer.header, label: answer.header });
              });
            }
          });
        }
      });
    }
    setFilterValues(answersTemp);
  }
  function handleFilterValuesChange(value: any) {
    setselectedFilterValues(value);
  }
  function handleSelectedTimeintervalChnage(value: any) {
    setSelectedTimeInterval(value);
  }

  function aggregateToggleChange(checked: any) {
    setAggregateTabulation(checked);
    console.log(checked);
  }
  function absoluteCountToggleChange(checked: any) {
    setAbsoluteCount(checked);
    console.log(checked);
  }
  function handleRecieversChange(value: any) {
    setRecievers(value);
  }

  async function downloadExcel() {
    setLoadingtab(true);
    let filterParam = null;
    console.log(filterValues);
    if (selectedFilter && filterValues && filterValues.length > 0) {
      const tempFilterValue = selectedFilterValues.join(",");
      console.log("#########################");
      console.log(tempFilterValue);
      filterParam = `${selectedFilter}:${tempFilterValue}`;
    }
    const requestBody = {
      surveyId: selectedSurvey.id,
      breakdown:
        selectedQuestion && selectedQuestion !== "none"
          ? selectedQuestion.id
          : 0,
      header: selectedQuestion ? selectedQuestion.header : null,
      filter: filterParam
    };
    try {
      await axios
        .post(API_URL + "/internaltool/tabulation-breakdown", requestBody, {
          responseType: "arraybuffer",
          timeout: 1800000
        })
        .then(response => {
          var blob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          FileSaver.saveAs(
            blob,
            `tabulation_${selectedSurvey.id}_${new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '')}`
          );
        });
      setLoadingtab(false);
      NotificationManager.success(
        "File Downloaded Succesfully",
        "Success !!",
        3000
      );
    } catch (err) {
      setLoadingtab(false);
      NotificationManager.error("Please try again", "Error !!", 3000);
    }
  }
  async function downloadExcel_v2() {
    setLoadingtab(true);
    let breakdown: any = [];
    if (selectedInterlockedSplit) {
      selectedSplits.forEach((single_split: any) => {
        breakdown.push(`${single_split},${selectedInterlockedSplit}`);
      });
    } else {
      selectedSplits.forEach((single_split: any) => {
        breakdown.push(single_split);
      });
    }
    let filterParam = null;
    console.log(filterValues);
    if (selectedFilter && filterValues && filterValues.length > 0) {
      const tempFilterValue = selectedFilterValues.join(",");
      console.log("#########################");
      console.log(tempFilterValue);
      filterParam = `${selectedFilter}:${tempFilterValue}`;
    }
    const requestBody = {
      surveyId: selectedSurvey.id,
      breakdown: breakdown,
      timeInterval: selectedTimeInterval,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      rollingFlag: (rolling > 0 ? (rolling - 1) : rolling),
      showCount: absoluteCount,
      aggregate: aggregateTabulation,
      recievers: recievers,
      filter: filterParam,
      publishFlag: true

    };
    console.log(requestBody);
    // setLoadingtab(false);
    // const requestBody = {
    //   surveyId: selectedSurvey.id,
    //   breakdown:
    //     selectedQuestion && selectedQuestion !== "none"
    //       ? selectedQuestion.id
    //       : 0,
    //   header: selectedQuestion ? selectedQuestion.header : null,
    //   filter: filterParam
    // };
    try {
      axios.post(
        API_URL + "/internaltool/tabulation-breakdown_v2",
        requestBody
      );
      setLoadingtab(false);
      NotificationManager.success(
        "Email Will be Sent once report perparation is done",
        "Success !!",
        100000
      );
      // setLoadingtab(false);
      // NotificationManager.success(
      //   "File Downloaded Succesfully",
      //   "Success !!",
      //   3000
      // );
    } catch (err) {
      setLoadingtab(false);
      NotificationManager.error("Please try again", "Error !!", 3000);
    }
  }
  return (
    <Form>
      <Row style={{ width: "100%", marginBottom: 15 }}>
        <Col span={4}>Survey:</Col>
        <Col span={4}>
          <Select
            showSearch
            dropdownMatchSelectWidth={false}
            allowClear
            style={{ width: "100%" }}
            options={surveydd}
            placeholder="Please Select Survey"
            onChange={handleSurveyChange}
            filterOption={(input, option) => {return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}}
            optionFilterProp="children"
          ></Select>
        </Col>
      </Row>
      <Row style={{ width: "100%", marginBottom: 15 }}>
        <Col span={4}>Dates:</Col>
        <Col span={5}>
          <Space direction="vertical" size={12}>
            <RangePicker
              onCalendarChange={(date: any, dateString: any) => {
                console.log(dateString);
                setSelectedStartDate(dateString[0]);
                setSelectedEndDate(dateString[1]);
              }}
            />
          </Space>
        </Col>
      </Row>
      <Row style={{ width: "100%", marginBottom: 15 }}>
        <Col span={4}>Aggregate:</Col>
        <Col span={4}>
          <Switch defaultChecked onChange={aggregateToggleChange} />
        </Col>
      </Row>
      {!aggregateTabulation && (
        <Row style={{ marginBottom: 15 }}>
          <Col span={4}>Cycles:</Col>
          <Col span={4}>
            <Select
              dropdownMatchSelectWidth={false}
              allowClear
              style={{ width: "100%" }}
              options={[
                { value: "date", key: "date" },
                { value: "week", key: "week" },
                { value: "weekmonth", key: "weekmonth" },
                { value: "month", key: "month" },
                { value: "monthname", key: "monthname" },
                { value: "quarter", key: "quarter" },
                { value: "year", key: "year" }
              ]}
              placeholder="Please Select Time Interval"
              onChange={handleSelectedTimeintervalChnage}
            ></Select>
          </Col>
        </Row>
      )}
      {!aggregateTabulation && (
        <Row style={{ marginBottom: 15 }}>
          <Col span={4}>Rolling:</Col>
          <Col span={4}>
            <Input
              style={{ width: "100%" }}
              type="number"
              onChange={e => {
                console.log(e.target.value);
                setRolling(e.target.value);
              }}
            />
          </Col>
        </Row>
      )}
      <Row style={{ marginBottom: 15 }}>
        <Col span={4}>Splits Question:</Col>
        <Col span={4}>
          <Select
            showSearch
            filterOption={(input, option) => {return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}}
            optionFilterProp="children"
            mode="multiple"
            dropdownMatchSelectWidth={false}
            allowClear
            style={{ width: "100%" }}
            options={questions}
            placeholder="Please Select Split Question"
            onChange={handleSplitsChange}
          ></Select>
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Col span={4}>Interlocked Split:</Col>
        <Col span={4}>
          <Select
            showSearch
            filterOption={(input, option) => {return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}}
            optionFilterProp="children"
            dropdownMatchSelectWidth={false}
            allowClear
            style={{ width: "100%" }}
            options={questions}
            placeholder="Please Select Interlocked Split"
            onChange={handleInterlockedQuestionChange}
          ></Select>
        </Col>
      </Row>
      <Row style={{ width: "100%", marginBottom: 15 }}>
        <Col span={4}>Absolute Counts:</Col>
        <Col span={4}>
          <Switch onChange={absoluteCountToggleChange} />
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Col span={4}>Filter:</Col>
        <Col span={4}>
          <Select
            showSearch
            filterOption={(input, option) => {return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}}
            optionFilterProp="children"
            dropdownMatchSelectWidth={false}
            allowClear
            style={{ width: "100%" }}
            options={questions}
            placeholder="Please Select Filter"
            onChange={handleFilterChange}
          ></Select>
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Col span={4}>Filter Values:</Col>
        <Col span={4}>
          <Select
            showSearch
            filterOption={(input, option) => {return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}}
            optionFilterProp="children"
            mode="multiple"
            dropdownMatchSelectWidth={false}
            allowClear
            style={{ width: "100%" }}
            options={filterValues}
            placeholder="Please Select Filter Values"
            onChange={handleFilterValuesChange}
          ></Select>
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Col span={4}>Recievers:</Col>
        <Col span={4}>
          <Select
            dropdownMatchSelectWidth={false}
            allowClear
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Please Enter Recievers"
            onChange={handleRecieversChange}
          ></Select>
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Button
          disabled={
            loadingtab || !recievers || (recievers && recievers.length === 0) || !selectedSplits || (selectedSplits && selectedSplits.length === 0)
          }
          style={{ float: "right", marginBottom: "-3%" }}
          onClick={() => {
            // downloadExcel();
            downloadExcel_v2();
          }}
        >
          Submit
        </Button>
      </Row>
      {loadingtab && (
        <Loader
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)"
          }}
          type="BallTriangle"
          color="#00BFFF"
          height="120"
          width="80"
        />
      )}
      <NotificationContainer />
    </Form>
  );
};
export default Tabulation;
