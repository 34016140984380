import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import {
  Cascader,
  Row,
  Col,
  Form,
  Button,
  Select,
  DatePicker,
  Space,
  Switch,
  Input
} from "antd";
import Loader from "react-loader-spinner";
// @ts-ignore
import {
  NotificationContainer,
  NotificationManager
  // @ts-ignore
} from "react-notifications";
import "react-notifications/lib/notifications.css";
// @ts-ignore
import readXlsxFile from "read-excel-file";
import DataTable from "react-data-table-component";
import "./vouchers.scss";
import { async } from "q";
type ReportsComponentProps = {
  // surveys: any;
};
const Reports: React.FC<ReportsComponentProps> = props => {
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [loadingtab, setLoadingtab] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const [selectedReport, setSelectedReport] = useState<any>();
  const [selectedStartDate, setSelectedStartDate] = useState<any>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<any>(null);
  const [rolling, setRolling] = useState<any>(null);
  const [selectedTimeInterval, setSelectedTimeInterval] = useState<any>(null);
  const [recievers, setRecievers] = useState<any>([]);
  const { RangePicker } = DatePicker;
  // useEffect(() => {
  //   const fetchVouchers = async () => {
  //     const response = await axios.get(`${API_URL}/internaltool/getVouchers`);
  //     console.log(response);
  //     setVouchers(response.data);
  //   };
  //   fetchVouchers();
  // }, []);
  async function handleReportChange(value: any) {
    setSelectedReport(value);
    setDisableSubmit(false);
  }
  function handleSelectedTimeintervalChnage(value: any) {
    setSelectedTimeInterval(value);
  }
  function handleRecieversChange(value: any) {
    setRecievers(value);
  }
  async function extractReport() {
    const requestBody = {
      rollingFlag: (rolling > 0 ? (rolling-1) : rolling),
      timeInterval: selectedTimeInterval,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      recievers: recievers
    };
    if (selectedReport === "Coke Report") {
      axios.post(API_URL + "/internaltool/coke-report", requestBody);
      setLoadingtab(false);
      NotificationManager.success(
        "Email Will be Sent once report perparation is done",
        "Success !!",
        5000
      );
    }
  }
  return (
    <Form>
      <Row style={{ width: "100%", marginBottom: 15 }}>
        <Col span={4}>Survey:</Col>
        <Col span={4}>
          <Select
            dropdownMatchSelectWidth={false}
            allowClear
            style={{ width: "100%" }}
            options={[{ value: "Coke Report", label: "Coke Report" }]}
            placeholder="Please Select Report"
            onChange={handleReportChange}
          ></Select>
        </Col>
      </Row>
      <Row style={{ width: "100%", marginBottom: 15 }}>
        <Col span={4}>Dates:</Col>
        <Col span={5}>
          <Space direction="vertical" size={12}>
            <RangePicker
              onCalendarChange={(date: any, dateString: any) => {
                setSelectedStartDate(dateString[0]);
                setSelectedEndDate(dateString[1]);
              }}
            />
          </Space>
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Col span={4}>Rolling:</Col>
        <Col span={4}>
          <Input
            style={{ width: "100%" }}
            type="number"
            onChange={e => {
              console.log(e.target.value);
              setRolling(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Col span={4}>Time Interval:</Col>
        <Col span={4}>
          <Select
            dropdownMatchSelectWidth={false}
            allowClear
            style={{ width: "100%" }}
            options={[
              { value: "date", key: "date" },
              { value: "week", key: "week" },
              { value: "weekmonth", key: "weekmonth" },
              { value: "month", key: "month" },
              { value: "monthname", key: "monthname" },
              { value: "quarter", key: "quarter" },
              { value: "year", key: "year" }
            ]}
            placeholder="Please Select Time Interval"
            onChange={handleSelectedTimeintervalChnage}
          ></Select>
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Col span={4}>Recievers:</Col>
        <Col span={4}>
          <Select
            dropdownMatchSelectWidth={false}
            allowClear
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Please Enter Recievers"
            onChange={handleRecieversChange}
          ></Select>
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Button
          disabled={
            loadingtab ||
            disableSubmit ||
            !rolling || 
            !selectedTimeInterval ||
            !recievers || (recievers && recievers.length === 0)
          }
          style={{ float: "right", marginBottom: "-3%" }}
          onClick={() => {
            extractReport();
          }}
        >
          Submit
        </Button>
      </Row>
      {loadingtab && (
        <Loader
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)"
          }}
          type="BallTriangle"
          color="#00BFFF"
          height="120"
          width="80"
        />
      )}
      <NotificationContainer />
    </Form>
  );
};
export default Reports;
