import React, { useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import { Row, Col, Form, Select, Button, Result } from "antd";
import Loader from "react-loader-spinner";
// @ts-ignore
import {
  NotificationContainer,
  NotificationManager,
  // @ts-ignore
} from "react-notifications";
import "react-notifications/lib/notifications.css";
// @ts-ignore
import readXlsxFile from "read-excel-file";

type OpenEndedCodingComponentProps = {
  surveys: any;
};
const OpenEndedCoding: React.FC<OpenEndedCodingComponentProps> = (props) => {
  const [surveys, setSurveys] = useState<any>([]);
  const [surveydd, setSurveydd] = useState<any>([]);
  const [selectedSurvey, setSelectedSurvey] = useState<any>();
  const [questions, setQuestions] = useState<any>([]);
  const [loadingtab, setLoadingtab] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState<any>();
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [csvFile, setCsvFile] = useState<string>("");
  const [showResult, setShowResult] = useState<boolean>(false);
  const [labeledQuestion, setLabeledQuestion] = useState<any>();

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchSurvey = async () => {
      setSurveys(props.surveys);
      if (
        props.surveys &&
        props.surveys.data &&
        props.surveys.data.length > 0
      ) {
        setSurveydd(
          props.surveys.data.map((survey: any) => {
            return { value: survey.id, label: survey.name };
          })
        );
      }
    };
    fetchSurvey();
  }, [props.surveys]);

  function handleSurveyChange(value: any) {
    const questionsTemp: any = [];
    surveys.data.map((survey: any) => {
      if (survey.id === value) {
        setSelectedSurvey(survey);
        if (survey.sections) {
          survey.sections.map((section: any) => {
            if (section && section.questions) {
              section.questions.map((question: any) => {
                if (question.isActive && question.type === "shortAnswer") {
                  questionsTemp.push({
                    value: question.id,
                    label: question.header,
                  });
                }
              });
            }
          });
        }
      }
    });
    setQuestions(questionsTemp);
  }

  function handleQuestionChange(value: any) {
    if (selectedSurvey.sections) {
      selectedSurvey.sections.map((section: any) => {
        if (section && section.questions) {
          section.questions.map((question: any) => {
            if (question && question.id === value) {
              setSelectedQuestion(question);
              setDisableSubmit(false);
            }
          });
        }
      });
    }
  }

  function downloadFile() {
    var blob = new Blob([csvFile], {
      type: "text/csv;charset=utf-8;",
    });
    var link = document.createElement("a");
    var url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${selectedQuestion.header}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function onCancelClick() {
    setDisableSubmit(false);
    setShowResult(false);
    setCsvFile("");
  }

  async function onCodeClick() {
    setLoadingtab(true);
    setShowResult(false);
    if (csvFile.length > 0) {
      const rows = csvFile.split("\n");
      rows.shift();
      rows.pop();
      const responses = rows.map((row) => {
        const rowArray = row.split(",");
        return {
          sessionId: rowArray[0],
          value: rowArray[rowArray.length-1],
        };
      });
      try {
        const requestBody = {
          surveyId: selectedSurvey.id,
          questionId: labeledQuestion,
          responses: responses,
        };
        await axios
          .post(API_URL + "/internaltool/openEnded-coding", requestBody)
          .then((response) => {
            setLoadingtab(false);
            setDisableSubmit(false);

            NotificationManager.success(
              `Done updating ${response.data.count} rows!`,
              "Success !!"
            );
          });
      } catch (err) {
        setLoadingtab(false);
        setDisableSubmit(false);
        setShowResult(true);
        NotificationManager.error("Please try again", "Error !!");
      }
    } else NotificationManager.error("No answers to code", "Error !!");
  }

  async function codeQuestion() {
    setLoadingtab(true);
    const res = await axios.get(
      API_URL +
        `/internaltool/openEnded-coding/${selectedSurvey.id}?questionId=${selectedQuestion.id}`
    );
    if (res.data.data && res.data.data[0]) {
      const result = res.data.data[0];
      setLabeledQuestion(result.labelQuestionId);
      try {
        const requestBody = {
          survey_id: selectedSurvey.id,
          question_id: selectedQuestion.id,
          labeled_question_id: result.labelQuestionId,
          model_name: result.modelName,
        };

        await axios
          .post(
            // API_URL +
            "/research/ml/open_ended",
            requestBody
          )
          .then((response) => {
            setLoadingtab(false);
            setCsvFile(response.data);
            setShowResult(true);
            setDisableSubmit(true);
          })
          .catch((err) => {
            setLoadingtab(false);
            NotificationManager.error("Please Try again", "Error !!");
          });
      } catch (err) {
        setLoadingtab(false);
        NotificationManager.error("Please Try again", "Error !!");
      }
    } else {
      NotificationManager.error("No Coding Found", "Error !!");
      setLoadingtab(false);
    }
  }

  return (
    <Form>
      <Row style={{ width: "100%", marginBottom: 15 }}>
        <Col span={4}>Survey:</Col>
        <Col span={8}>
          <Select
            showSearch
            dropdownMatchSelectWidth={false}
            allowClear
            style={{ width: "100%" }}
            options={surveydd}
            placeholder="Please Select Survey"
            onChange={handleSurveyChange}
            filterOption={(input, option) => {return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}}
            optionFilterProp="children"
          ></Select>
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Col span={4}>Question:</Col>
        <Col span={8}>
          <Select
            showSearch
            defaultValue={"none"}
            dropdownMatchSelectWidth={false}
            allowClear
            style={{ width: "100%" }}
            options={questions}
            placeholder="Please Select Question"
            onChange={handleQuestionChange}
            filterOption={(input, option) => {return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}}
            optionFilterProp="children"
          ></Select>
        </Col>
      </Row>

      <Row style={{ marginBottom: 15 }}>
        <Button
          disabled={loadingtab || disableSubmit}
          style={{ float: "right", marginBottom: "-3%" }}
          onClick={codeQuestion}
        >
          Submit
        </Button>
      </Row>
      {loadingtab && (
        <Loader
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
          type="BallTriangle"
          color="#00BFFF"
          height="120"
          width="80"
        />
      )}
      <NotificationContainer />
      {showResult && (
        <Result
          status="success"
          title={`Successfully labeled ${
            csvFile.split("\n").length - 2
          } answers.`}
          extra={[
            <Button onClick={downloadFile}>Preview</Button>,
            <Button onClick={onCodeClick}>Code</Button>,
            <Button onClick={onCancelClick}>Cancel</Button>,
          ]}
        />
      )}
    </Form>
  );
};
export default OpenEndedCoding;
