import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import readXlsxFile from 'read-excel-file';
import {
  Cascader,
  Row,
  Col,
  Form,
  Button,
  Select,
  DatePicker,
  Space,
  Switch,
  Input
} from "antd";
import Loader from "react-loader-spinner";
// @ts-ignore
import {
  NotificationContainer,
  NotificationManager
  // @ts-ignore
} from "react-notifications";
import "react-notifications/lib/notifications.css";
type publishDataComponentProps = {
  surveys: any;
};
const PublishData: React.FC<publishDataComponentProps> = props => {
  const [surveys, setSurveys] = useState<any>([]);
  const [surveydd, setSurveydd] = useState<any>([]);
  const [selectedSurvey, setSelectedSurvey] = useState<any>();
  const [questions, setQuestions] = useState<any>([]);
  const API_URL = process.env.REACT_APP_API_URL;
  const [loadingtab, setLoadingTab] = useState(false);
  const [qcrules, setQcrules] = useState<any>([]);
  const [selectedqcrules, setSelectedqcrules] = useState<any>([]);
  const [selectedStartDate, setSelectedStartDate] = useState<any>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<any>(null);
  const [sessionIds, setSessionIds] = useState<any>([]);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  const [isFilesUploaded, setIsFilesUploaded] = useState<boolean>(false);
  const [isDatePicked, setIsDatePicked] = useState<boolean>(false);

  const { RangePicker } = DatePicker;
  useEffect(() => {
    const fetchSurvey = async () => {
      setSurveys(props.surveys);
      if (
        props.surveys &&
        props.surveys.data &&
        props.surveys.data.length > 0
      ) {
        setSurveydd(
          props.surveys.data.map((survey: any) => {
            return { value: survey.id, label: survey.name };
          })
        );
      }
    };
    fetchSurvey();
  }, [props.surveys]);

  

  async function handleSurveyChange(value: any) {
    setSelectedSurvey(value);
  }

  async function parseData(e: React.ChangeEvent<HTMLInputElement>) {  
    setLoadingTab(true);

    if (!e.target.files || e.target.files.length === 0) {
      setIsFilesUploaded(false);
      setLoadingTab(false);

      // Handle case where no file is selected
      NotificationManager.error("No file selected", "Error !!", 3000);
      return;
    }
    
    try {
      const file = e.target.files[0];
      const rows = await readXlsxFile(file);
  
      const sessionIds = rows.map((row: any) => row[0]);

      // remove the first element from the array if it's not a number (ex: Header like "SessionIds");
      if(isNaN(sessionIds[0])) sessionIds.shift();
  
      setSessionIds(sessionIds)
      setIsFilesUploaded(true);
      setDisableSubmit(false);
    } catch (error) {
      console.error("Error parsing file:", error);
      NotificationManager.error("Error parsing file. Please check the file format.", "Error !!", 3000);
    } finally {
      setLoadingTab(false);
    }
  }
  

  async function publishData() {
    setLoadingTab(true);
    try {
      let requestBody = {};
      if (isDatePicked) {
        requestBody = {
          surveyId: selectedSurvey,
          fromDate: selectedStartDate,
          toDate: selectedEndDate,
        };
      } else if (isFilesUploaded) {
        requestBody = {
          surveyId: selectedSurvey,
          sessionIds: sessionIds
        };
      }
      const response = await axios.post(API_URL + "/internaltool/publish-data", requestBody);
      setLoadingTab(false);
      NotificationManager.success(
        `${response.data.published_count} Respondents published successfully`,
        "Success !!",
        3000
      );
    } catch (err) {
      setLoadingTab(false);
      NotificationManager.error("Please try again", "Error !!", 3000);
    }
  }
  
  async function unpublishData() {
    setLoadingTab(true);
    try {
      let requestBody = {};
      if (isDatePicked) {
        requestBody = {
          surveyId: selectedSurvey,
          fromDate: selectedStartDate,
          toDate: selectedEndDate,
        };
      } else if (isFilesUploaded) {
        requestBody = {
          surveyId: selectedSurvey,
          sessionIds: sessionIds
        };
      }
      const response = await axios.post(API_URL + "/internaltool/unpublish-data", requestBody);
      setLoadingTab(false);
      NotificationManager.success(
        `${response.data.published_count} Respondents unpublished successfully`,
        "Success !!",
        3000
      );
    } catch (err) {
      setLoadingTab(false);
      NotificationManager.error("Please try again", "Error !!", 3000);
    }
  }

  useEffect(() => {
    if((isDatePicked && selectedSurvey) || isFilesUploaded){
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [selectedSurvey, isDatePicked])
  
  return (
    <Form>
      <Row style={{ width: "100%", marginBottom: 15 }}>
        <Col span={4}>Survey:</Col>
        <Col span={4}>
          <Select
            showSearch
            dropdownMatchSelectWidth={false}
            allowClear
            style={{ width: "100%" }}
            options={surveydd}
            placeholder="Please Select Survey"
            onChange={handleSurveyChange}
            filterOption={(input, option) => {return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}}
            optionFilterProp="children"
          ></Select>
        </Col>
      </Row>
      <Row style={{ width: "100%", marginBottom: 15 }}>
        <Col span={4}>Dates:</Col>
        <Col span={5}>
          <Space direction="vertical" size={12}>
            <RangePicker
            disabled={isFilesUploaded}
              onCalendarChange={(date: any, dateString: any) => {
                setSelectedStartDate(dateString[0]);
                setSelectedEndDate(dateString[1]);
                
                if(dateString[0] && dateString[1]) setIsDatePicked(true);
                else setIsDatePicked(false);
              }}
            />  
          </Space>
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Col span={4}>Upload Respondents:</Col>
        <Col span={4}>
          <Input
          disabled={isDatePicked}
            style={{ width: "100%" }}
            type="file"
            accept=".xlsx"
            onChange={e => {
              parseData(e);
            }}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Col>
          <Button
            disabled={loadingtab || disableSubmit}
            style={{ float: "right", marginBottom: "-3%" }}
            onClick={() => {
              publishData();
            }}
          >
            Publish
          </Button>
        </Col>
        <Col>
          <Button
            disabled={loadingtab || disableSubmit}
            style={{ float: "right", marginBottom: "-3%" }}
            onClick={() => {
              unpublishData();
            }}
          >
            UnPublish
          </Button>
        </Col>
      </Row>
      {loadingtab && (
        <Loader
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)"
          }}
          type="BallTriangle"
          color="#00BFFF"
          height="120"
          width="80"
        />
      )}
      <NotificationContainer />
    </Form>
  );
};
export default PublishData;
