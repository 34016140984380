import React, { useState, Component, useEffect } from 'react';
import 'chartjs-plugin-datalabels';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from 'react-router-dom';
import logo, { ReactComponent } from './logo.svg';
import { connect } from 'react-redux';
// import './App.scss';
import internalTools from './pages/internalTools';
import Login from './login';

const App: React.FC = () => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('logged') || 'false')
  );

  function handleLogin() {
    localStorage.setItem('logged', 'true');
    setUser(localStorage.getItem('logged'));
  }
  // const PrivateRoute = ({ component, isAuthenticated, ...rest }: any) => {
  //   const routeComponent = (props: any) => React.createElement(component, props);
  //   return <Route {...rest} render={routeComponent} />;
  // };
  // return (
  //   <BrowserRouter>
  //     <Route exact path="/login" component={Login} />
  //     <PrivateRoute exact path="/tool" component={internalTools} />
  //   </BrowserRouter>
  // );

  const PrivateRoute = ({ component, isAuthenticated, ...rest }: any) => {
    const routeComponent = (props: any) => {
      if (isAuthenticated) {
        return React.createElement(component, props);
      } else {
        return <Redirect to={{ pathname: '/login' }} />;
      }
    };
    return <Route {...rest} render={routeComponent} />;
  };

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={Login}>
          {user ? (
            <Redirect to="/tools" />
          ) : (
            <Route
              render={(props) => <Login {...props} logged={handleLogin} />}
            />
          )}
        </Route>

        <Route exact path="/" component={Login}>
          {user ? <Redirect to="/tools" /> : <Redirect to="/login" />}
        </Route>

        <PrivateRoute
          exact
          path="/tools"
          component={internalTools}
          isAuthenticated={user}
        ></PrivateRoute>
      </Switch>
    </BrowserRouter>
  );
};
export default App;
