import React, { useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import {
  Row,
  List,
  Col,
  Input,
  Form,
  Button,
  Select,
  Popover,
  DatePicker
} from "antd";
import Loader from "react-loader-spinner";
// @ts-ignore
import {
  NotificationContainer,
  NotificationManager
  // @ts-ignore
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Key } from 'rc-select/lib/interface/generator';

import Axios from "axios";
const { Option } = Select;

type ExportRawDataComponentProps = {
  surveys: any;
};
const ExportRawData: React.FC<ExportRawDataComponentProps> = props => {
  var FileSaver = require("file-saver");
  const [surveys, setSurveys] = useState<any>([]);
  const [surveydd, setSurveydd] = useState<any>([]);
  const [selectedSurvey, setSelectedSurvey] = useState<any>();
  const [questions, setQuestions] = useState<any>([]);
  const API_URL = process.env.REACT_APP_API_URL;
  const [loadingtab, setLoadingtab] = useState(false);
  const [recievers, setRecievers] = useState<any>([]);
  const [selectedStartDate, setSelectedStartDate] = useState<any>();
  const [selectedEndDate, setSelectedEndDate] = useState<any>();
  const [historicalIntervals, setHistoricalIntervals] = useState<any>([]);
  const { RangePicker } = DatePicker;

  useEffect(() => {
    const fetchSurvey = async () => {
      setSurveys(props.surveys);
      if (
        props.surveys &&
        props.surveys.data &&
        props.surveys.data.length > 0
      ) {
        setSurveydd(
          props.surveys.data.map((survey: any) => {
            return { value: survey.id, label: survey.name };
          })
        );
      }
    };
    console.log(loadingtab, "***************");

    const fetchIntervals = async () => {
      if (selectedSurvey && selectedSurvey?.type !== 'adhoc') {
        const body = {
          id: selectedSurvey.id,
          type: selectedSurvey.type,
          startOfWeek: selectedSurvey.startOfWeek,
        };
        Axios.post(API_URL + '/internaltool/quota-intervals', body)
          .then(response => {
            setHistoricalIntervals(response.data);
          })
          .catch(err => {
            console.log(err);
          });
      }
    };
    fetchIntervals();
    fetchSurvey();
  }, [props.surveys]);
  function handleSurveyChange(value: any) {
    const questionsTemp: any = [];
    surveys?.data.map((survey: any) => {
      if (survey.id === value) {
        setSelectedSurvey(survey);
        survey.sections?.map((section: any) => {
          section.questions.map((questions: any) => {
            questionsTemp.push(questions)
          })
        }
        )
      }
    });
    setQuestions(questionsTemp)
  }
  const onFinish = (values: any) => {
    setLoadingtab(true);
    try {
      const startInterv: any = [];
      const endInterv: any = [];

      values.interval?.map((val: any) => {
        const chosenInterval = historicalIntervals.find(
          (interv: any, index: Number) => index == val
        );
        startInterv.push(chosenInterval.startDate);
        endInterv.push(chosenInterval.endDate);
      });
      const sorted_startDates = startInterv.sort((a: any, b: any) => new Date(b).getTime() - new Date(a).getTime()).slice(-1)[0];
      const sorted_endDates = endInterv.sort((a: any, b: any) => new Date(b).getTime() - new Date(a).getTime())[0];
      const now = (new Date(Date.now()).toLocaleString().split(", "))[0];
      const dateFinal = now.split("/");
      const date = dateFinal[1] + "_" + dateFinal[0] + "_" + dateFinal[2];
      let chosenQuestions: any = [];
      values.questions?.map((ques: number) => {
        const gridQ: any = questions?.find((q: any) => q.id == ques && q.type == "grid");
        if (gridQ) {
          gridQ.questions?.map((inGrid: any) => {
            chosenQuestions.push(inGrid.id);
          })

        } else {
          chosenQuestions.push(ques);
        }
      })
      const requestBody = {
        surveyId: selectedSurvey.id,
        surveyType: selectedSurvey.type,
       
        startDate: selectedStartDate
          ? selectedStartDate :
          sorted_startDates?.replace('T', ' ').split('.')[0],

        endDate: selectedEndDate
          ? selectedEndDate :
          sorted_endDates?.replace('T', ' ').split('.')[0],
        adminFlag: true,
        userFlag: true,
        qArray: values.questions && values.questions.length > 0
          ? chosenQuestions.toString()
          : undefined,
        publishedFlag: true,
        format: values.format
      };
      axios
        .post(`${API_URL}/internaltool/rawdata`, requestBody, {
          responseType: "arraybuffer"
        })
        .then((response: any) => {
          setLoadingtab(true);

          console.log(response);
          var blob = new Blob([response.data], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          FileSaver.saveAs(
            blob,
            `rawData_${selectedSurvey.id}_${new Date()
              .toISOString()
              .replace(/T/, ' ')
              .replace(/\..+/, '')}`
          );
          setLoadingtab(false);
          NotificationManager.success(
            'File Downloaded Succesfully',
            'Success !!',
            3000
          );
        })
        .catch(err => {
          setLoadingtab(false)
          NotificationManager.error("Please try again", "Error !!", 3000)
        }
        )

    } catch (err) {
      NotificationManager.error("Please Try again", "Error !!");
    }
  }
  function handleRecieversChange(value: any) {
    setRecievers(value);
  }


  return (
    <Form onFinish={onFinish}>
      <Row style={{ width: "100%", marginBottom: 15 }}>
      <Col span={4}>
        <Form.Item name="survey" label="Survey"        
         rules={[{ required: true, message: 'Please select a survey' }]}>
          <Select
            showSearch
            dropdownMatchSelectWidth={false}
            allowClear
            style={{ width: "100%" }}
            options={surveydd}
            placeholder="Please Select Survey"
            onChange={handleSurveyChange}
            filterOption={(input, option) => { return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0 }}
            optionFilterProp="children"

          ></Select>
        </Form.Item>   
        </Col>   
        </Row>
      <Form.Item name="questions" label="Questions">
        <Select
          mode="multiple"
          style={{ width: '50%' }}
          placeholder="Please Select Exported Questions"
          dropdownMatchSelectWidth={false}
          allowClear
        >
          {questions?.map((question: any) => {
            return <Option value={question.id}>{question.header}</Option>;
          })}
        </Select>
      </Form.Item>

      <Row style={{ width: "100%", marginBottom: 15 }}>
      <Form.Item name="date" label="Date Range" >
                  <RangePicker
            style={{ width: "100%" }}
            format="YYYY-MM-DD 00:00:00"
            onCalendarChange={(date: any, dateString: any) => {
              console.log(dateString);
              setSelectedStartDate(dateString[0]);
              setSelectedEndDate(dateString[1]);
            }}
            showTime={false}
          />
          </Form.Item>
      </Row>
      {selectedSurvey?.type !== 'adhoc' && (
          <Form.Item label="Interval" name="interval">
            <Select
              mode="multiple"
              dropdownMatchSelectWidth={false}
              allowClear
              style={{ width: '100%' }}
              placeholder="Please Select Interval"

            >
              {historicalIntervals.map((interval: any, index: Key) => {
                return <Option value={index}>{interval.customInterval}</Option>;
              })}
            </Select>
          </Form.Item>
        )}
      <Form.Item name="format" label="Format"
        rules={[{ required: true, message: 'Please pick from dropdown' }]}
      >
        <Select style={{ width: '50%' }}
          placeholder="Please pick from dropdown"
          dropdownMatchSelectWidth={false}>
          <Option value="defaultFormat"> Default Format</Option>
          <Option value="MicrosoftBIFormat"> Microsoft Power BI Format</Option>
        </Select>
      </Form.Item>
      {/* <Form.Item style={{ marginBottom: 15 }}>
        <Col span={4}>Recievers:</Col>
        <Col span={4}>
          <Select
            dropdownMatchSelectWidth={false}
            allowClear
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Please Enter Recievers"
            onChange={handleRecieversChange}
          ></Select>
        </Col>
      </Form.Item> */}

      {/* <Form.Item style={{ marginBottom: 15 }}> */}
      <Button
        disabled={
          loadingtab
        }
        style={{ float: "left", marginBottom: "-3%" }}
        htmlType="submit"

      // onClick={() => {
      //   exportRawData();
      // }}
      >
        Submit
      </Button>
      {/* </Form.Item> */}
      {loadingtab && (
        <Loader
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)"
          }}
          type="BallTriangle"
          color="#00BFFF"
          height="120"
          width="80"
        />
      )}
      <NotificationContainer />
    </Form>
  );
};
export default ExportRawData;
