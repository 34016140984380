import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import axios from 'axios';
import {
  Cascader,
  Row,
  List,
  Col,
  Input,
  Form,
  Button,
  Typography,
  Table,
  Space,
  Card,
  Tooltip,
  Modal,
  Pagination,
  Select,
  Popover,
  Divider,
  DatePicker,
} from 'antd';
import Loader from 'react-loader-spinner';
// @ts-ignore
import {
  NotificationContainer,
  NotificationManager,
  // @ts-ignore
} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
// @ts-ignore
import readXlsxFile from 'read-excel-file';
const { RangePicker } = DatePicker;

type ChangeUserStatusComponentProps = {
  surveys: any;
};

const ChangeUserStatus: React.FC<ChangeUserStatusComponentProps> = (props) => {
  const [surveys, setSurveys] = useState<any>([]);
  const [surveydd, setSurveydd] = useState<any>([]);
  const [selectedSurvey, setSelectedSurvey] = useState<any>();
  const [selectedStatus, setSelectedStatus] = useState<any>();
  const [selectedForce, setSelectedForce] = useState<any>();
  const [questions, setQuestions] = useState<any>([]);
  const [respondentId, setRespondentId] = useState('');
  const [importedData, setImportedData] = useState<any>([]);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [loadingtab, setLoadingtab] = useState(false);
  const [finishedSurvey, setFinishedSurvey] = useState<any>();
  const [selectedDate, setSelectedDate] = useState<any>();
  const [selectedStartDate, setSelectedStartDate] = useState<any>();
  const [selectedEndDate, setSelectedEndDate] = useState<any>();

  const API_URL = process.env.REACT_APP_API_URL;
  var FileSaver = require('file-saver');

  useEffect(() => {
    const fetchSurvey = async () => {
      setSurveys(props.surveys);
      if (
        props.surveys &&
        props.surveys.data &&
        props.surveys.data.length > 0
      ) {
        setSurveydd(
          props.surveys.data.map((survey: any) => {
            return { value: survey.id, label: survey.name };
          })
        );
      }
    };
    fetchSurvey();
  }, [props.surveys]);

  function handleSurveyChange(value: any) {
    const questionsTemp: any = [];
    setFinishedSurvey(value);
    
    surveys.data.map((survey: any) => {
      if (survey.id === value) {
        setSelectedSurvey(survey);
      }
    });
    setQuestions(questionsTemp);
  }

  function handleStatusChange(value: any) {
    setSelectedStatus(value);
  }

  function handleForceChange(value: any) {
    setSelectedForce(!selectedForce);
  }

  async function parseData(e: any) {
    console.log('Parse data');
    console.log(e.target.files);
    setLoadingtab(true);
    let tempImportedData: any = [];
    await readXlsxFile(e.target.files[0]).then((rows: any) => {
      console.log('raws');
      console.log(rows);
      for (let i = 0; i < rows.length; i++) {
        tempImportedData.push(rows[i][0]);
      }
    });
    setImportedData(tempImportedData);
    setLoadingtab(false);
    setDisableSubmit(false);
  }

  async function changeToExpired() {
    const requestBody = {
      sessionId: importedData,
      surveyId: selectedSurvey.id,
      // }; try {
      //   await axios
      //     .post(API_URL + "/internaltool/change-to-expired", requestBody, {
      //     })
      //     .then(response => {
      //       setLoadingtab(false);
      //       NotificationManager.success("Status Changed to expired");
      //     })
      //     .catch(err => {
      //       setLoadingtab(false);
      //       NotificationManager.error("Please Try again", "Error !!");
      //     });
      // } catch (err) {
      //   setLoadingtab(false);
      //   NotificationManager.error("Please Try again", "Error !!");
      // }
    };
    try {
      await axios
        .post(API_URL + '/internaltool/change-to-expired', requestBody, {
          responseType: 'arraybuffer',
        })
        .then((response) => {
          console.log(response);
          var blob = new Blob([response.data], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          FileSaver.saveAs(
            blob,
            `expired_${selectedSurvey.id}_${new Date()
              .toISOString()
              .replace(/T/, ' ')
              .replace(/\..+/, '')}`
          );
          setLoadingtab(false);
          NotificationManager.success(
            'File Downloaded Succesfully',
            'Success !!',
            3000
          );
        })
        .catch((err) => {
          setLoadingtab(false);
          NotificationManager.error('Please try again', 'Error !!', 3000);
        });
    } catch (err) {
      setLoadingtab(false);
      NotificationManager.error('Please try again', 'Error !!', 3000);
    }
  }

  async function changeDate(value: any) {
    setSelectedDate(value);
  }

  async function changeResponseDate() {
    setLoadingtab(true);
    axios
      .post(`${API_URL}/internalTool/change-response-date`, {
        date: new Date(selectedDate)
          .toISOString()
          .slice(0, 19)
          .replace('T', ' '),
        sessions: importedData,
        surveyId: selectedSurvey.id,
      })
      .then((response) => {
        console.log('xxx', response);
        setLoadingtab(false);
        NotificationManager.success('Date Changed', 'Success');
      })
      .catch((err) => {
        console.log('xxx err', err);
        setLoadingtab(false);
        NotificationManager.error('Please Try again', 'Error !!');
      });
  }

  async function changeRespondentStatus() {
    try {
      const requestBody = {
        new_status: selectedStatus,
        force: selectedForce,
        sessionId: importedData,
        surveyId: selectedSurvey.id,
        user: localStorage.getItem('username'),
      };
      if (selectedStatus === 'terminated') {
        try {
          await axios
            .post(API_URL + '/internaltool/terminate', requestBody)
            .then((response) => {
              setLoadingtab(false);
              NotificationManager.success('Status Changed', 'Success');
            })
            .catch((err) => {
              setLoadingtab(false);
              NotificationManager.error('Please Try again', 'Error !!');
            });
        } catch (err) {
          setLoadingtab(false);
          NotificationManager.error('Please Try again', 'Error !!');
        }
      } else if (selectedStatus === 'submitted') {
        try {
          await axios
            .post(API_URL + '/internaltool/submit', requestBody, {
              responseType: 'arraybuffer',
            })
            .then((response) => {
              console.log('xxx', response);
              var blob = new Blob([response.data], {
                type:
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              FileSaver.saveAs(
                blob,
                `includeRespondents_${
                  selectedSurvey.id
                }_${new Date()
                  .toISOString()
                  .replace(/T/, ' ')
                  .replace(/\..+/, '')}`
              );
              setLoadingtab(false);
              NotificationManager.success(
                'File Downloaded Succesfully',
                'Success !!',
                3000
              );
            })
            .catch((err) => {
              setLoadingtab(false);
              NotificationManager.error('Please try again', 'Error !!', 3000);
            });
        } catch (err) {
          setLoadingtab(false);
          NotificationManager.error('Please try again', 'Error !!', 3000);
        }
      } else {
        try {
          await axios
            .post(API_URL + '/internaltool/change-status', requestBody)
            .then((response) => {
              NotificationManager.success('Status Changed', 'Success');
            })
            .catch((err) => {
              setLoadingtab(false);
              NotificationManager.error('Please Try again', 'Error !!');
            });
        } catch (err) {
          setLoadingtab(false);
          NotificationManager.error('Please Try again', 'Error !!');
        }
      }
    } catch (err) {
      setLoadingtab(false);
      NotificationManager.error('Please Try again', 'Error !!');
    }
  }

  async function changefromFinished() {
    if (finishedSurvey) {
      try {
        await axios
          .post(API_URL + '/internaltool/submitRespondant', {
            surveyId: finishedSurvey,
            startDate: selectedStartDate,
            endDate: selectedEndDate
          })
          .then((response) => {
            setLoadingtab(false);
            NotificationManager.success(
              'Status Changed',
              `Submitted : ${response.data.submitted}, Terminated:${response.data.terminated} `
            );
          })
          .catch((err) => {
            setLoadingtab(false);
            NotificationManager.error('Please Try again', 'Error !!');
          });
      } catch (err) {
        setLoadingtab(false);
        NotificationManager.error('Please Try again', 'Error !!');
      }
    }
  }

  return (
    <Form>
      <Row style={{ width: '100%', marginBottom: 50 }}>
        <Col xs={12}>
          <Divider style={{ width: '50%' }} plain type="horizontal">
            <h1>Change status to submitted</h1>
          </Divider>
          <Row style={{ width: '100%', marginBottom: 15 }}>
            <Col span={4}>Survey:</Col>
            <Col span={8}>
            <Select
            showSearch
            dropdownMatchSelectWidth={false}
            allowClear
            style={{ width: "100%" }}
            options={surveydd}
            placeholder="Please Select Survey"
            onChange={handleSurveyChange}
            filterOption={(input, option) => {return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}}
            optionFilterProp="children"
          ></Select>
            </Col>
          </Row>
          <Row style={{ marginBottom: 15 }}>
            <Col span={4}>Upload Respondents:</Col>
            <Col>
              <Input
                style={{ width: '100%' }}
                type="file"
                onChange={(e) => {
                  parseData(e);
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 15 }}>
            <Col span={4}>Status:</Col>
            <Col span={8}>
              <Select
                dropdownMatchSelectWidth={false}
                allowClear
                style={{ width: '100%' }}
                options={[{ value: 'submitted', label: 'submitted' }]}
                placeholder="Please Select Status"
                onChange={handleStatusChange}
              ></Select>
            </Col>
          </Row>
          <Row style={{ marginBottom: 15 }}>
            <Col span={4}>Force:</Col>
            <Col xs={2}>
              <Input
                name="force"
                type="checkbox"
                value={selectedForce}
                onChange={handleForceChange}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 15 }}>
            <Button
              disabled={loadingtab || disableSubmit}
              style={{ float: 'right', marginBottom: '-3%' }}
              onClick={() => {
                changeRespondentStatus();
              }}
            >
              Submit
            </Button>
          </Row>
        </Col>

        <Col xs={12}>
          <Divider plain type="horizontal">
            <h1>Convert finished to submitted/terminated:</h1>
          </Divider>
          <Row style={{ width: "100%", marginBottom: 15 }}>
            <Col span={4}>Survey:</Col>
            <Col span={8}>
              <Select
                showSearch
                dropdownMatchSelectWidth={false}
                allowClear
                style={{ width: "100%" }}
                options={surveydd}
                placeholder="Please Select Survey"
                onChange={handleSurveyChange}
                filterOption={(input, option) => {return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}}
                optionFilterProp="children"
              ></Select>
            </Col>
          </Row>
          <Row style={{ width: "100%", marginBottom: 15 }}>
            <Col span={4}>Date Range:</Col>
            <Col span={8}>
              <RangePicker
                style={{ width: "100%" }}
                format="YYYY-MM-DD 00:00:00"
                onCalendarChange={(date: any, dateString: any) => {
                  setSelectedStartDate(dateString[0]);
                  setSelectedEndDate(dateString[1]);
                }}
                showTime={false}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 15 }}>
            <Button
              disabled={!finishedSurvey}
              style={{ float: 'right', marginBottom: '-3%' }}
              onClick={() => {
                changefromFinished();
              }}
            >
              Submit
            </Button>
          </Row>
        </Col>
        <Col xs={12}>
          <Divider style={{ width: '50%' }} plain type="horizontal">
            <h1>Change status to expired:</h1>
          </Divider>
          <Row style={{ width: '100%', marginBottom: 20 }}>
            <Col span={4}>Survey:</Col>
            <Col span={8}>
            <Select
            showSearch
            dropdownMatchSelectWidth={false}
            allowClear
            style={{ width: "100%" }}
            options={surveydd}
            placeholder="Please Select Survey"
            onChange={handleSurveyChange}
            filterOption={(input, option) => {return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}}
            optionFilterProp="children"
          ></Select>
            </Col>
          </Row>
          <Row style={{ marginBottom: 15 }}>
            <Col span={4}>Upload Respondents:</Col>
            <Col>
              <Input
                style={{ width: '100%' }}
                type="file"
                onChange={(e) => {
                  parseData(e);
                }}
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: 15 }}>
            <Button
              disabled={loadingtab || disableSubmit}
              style={{ float: 'right', marginBottom: '-3%' }}
              onClick={() => {
                changeToExpired();
              }}
            >
              Submit
            </Button>
          </Row>
        </Col>

        {/* <Col xs={12}>
          <Divider style={{ width: '50%' }} plain type="horizontal">
            <h1>Change submit date:</h1>
          </Divider>
          <Row style={{ width: '100%', marginBottom: 20 }}>
            <Col span={4}>Survey:</Col>
            <Col>
              <Select
                dropdownMatchSelectWidth={false}
                allowClear
                style={{ width: '100%' }}
                options={surveydd}
                placeholder="Please Select Survey"
                onChange={handleSurveyChange}
              ></Select>
            </Col>
          </Row>
          <Row style={{ marginBottom: 15 }}>
            <Col span={4}>Upload Respondents:</Col>
            <Col>
              <Input
                style={{ width: '100%' }}
                type="file"
                onChange={(e) => {
                  parseData(e);
                }}
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: 15 }}>
            <Col span={4}>Date:</Col>
            <Col>
              <Space direction="vertical" size={12}>
                <DatePicker onChange={changeDate} />
              </Space>
            </Col>
          </Row>

          <Row style={{ marginBottom: 15 }}>
            <Button
              disabled={loadingtab || disableSubmit}
              style={{ float: 'right', marginBottom: '-3%' }}
              onClick={() => {
                changeResponseDate();
              }}
            >
              Submit
            </Button>
          </Row>
        </Col> */}
      </Row>

      <NotificationContainer />
    </Form>
  );
};

export default ChangeUserStatus;
