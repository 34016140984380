import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import {
  Cascader,
  Row,
  Col,
  Form,
  Button,
  Select,
  DatePicker,
  Space,
  Switch,
  Input
} from "antd";
import Loader from "react-loader-spinner";
// @ts-ignore
import {
  NotificationContainer,
  NotificationManager
  // @ts-ignore
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { identifier } from "@babel/types";
type identifeirRaffleComponentProps = {
  surveys: any;
};
const IdentifierRaffle: React.FC<identifeirRaffleComponentProps> = props => {
  const [surveys, setSurveys] = useState<any>([]);
  const [surveydd, setSurveydd] = useState<any>([]);
  const [selectedSurvey, setSelectedSurvey] = useState<any>();
  const [identifers, setIdentifiers] = useState<any>([]);
  const [identifersAll, setIdentifiersAll] = useState<any>([]);
  const [selectedIdentifer, setSelectedIdentifer] = useState<any>();
  const [brands, setBrands] = useState<any>([]);
  const [prices, setPrices] = useState<any>([]);
  const [hasRaffle, setHasRaffle] = useState<any>(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const [loadingtab, setLoadingtab] = useState(false);
  const [raffleModoluse, setRaffleModoluse] = useState<any>(0);
  const [form] = Form.useForm();
  useEffect(() => {
    const fetchSurvey = async () => {
      setSurveys(props.surveys);
      if (
        props.surveys &&
        props.surveys.data &&
        props.surveys.data.length > 0
      ) {
        setSurveydd(
          props.surveys.data.map((survey: any) => {
            return { value: survey.id, label: survey.name };
          })
        );
      }
    };
    fetchSurvey();
  }, [props.surveys]);

  async function handleSurveyChange(value: any) {
    setLoadingtab(true);
    setSelectedSurvey(value);
    await axios
      .get(API_URL + `/internaltool/surveyIdentifierDetails/${value}`)
      .then(response => {
        setIdentifiers(
          response.data.identifiers.map((identifier: any) => {
            return { value: identifier.id, label: identifier.identifier };
          })
        );
        setBrands(response.data.brands);
        setPrices(response.data.prices);
        setIdentifiersAll(response.data.identifiers);
        setSelectedIdentifer(null);
      });
    setLoadingtab(false);
  }
  async function handleIdentifierSelection(value: any) {
    for (let i = 0; i < identifersAll.length; i++) {
      if (identifersAll[i].id === value) {
        setSelectedIdentifer(identifersAll[i]);
        form.setFieldsValue({
          rafflethreshold: identifersAll[i].raffleModulus
        });
        setRaffleModoluse(
          identifersAll[i].raffleModulus
            ? parseInt(identifersAll[i].raffleModulus)
            : 0
        );
        setHasRaffle(identifersAll[i].hasRaffle);
        if (identifersAll[i].vouchers) {
          form.setFieldsValue({
            voucherbrands: identifersAll[i].vouchers.brands
          });
          form.setFieldsValue({
            voucherprices: identifersAll[i].vouchers.prices
          });
        }
      }
    }
  }
  async function update(values: any) {
    setLoadingtab(true);
    try {
      let identifier_vouchers = values.voucherbrands.map((brand: any) => {
        return { type: "brand", value: brand };
      });
      values.voucherprices.map((price: any) => {
        identifier_vouchers.push({ type: "price", value: price });
      });
      let request_body: any = {
        id: selectedIdentifer.id,
        raffleModulus: raffleModoluse,
        hasRaffle: hasRaffle,
        identifierVoucher: identifier_vouchers
      };
      await axios
        .post(API_URL + `/internaltool/updateIdentifierRaffle/`, request_body)
        .then(response => {
          NotificationManager.success("Identifier Updated!!", "Success");
        });
      setLoadingtab(false);
      console.log(request_body);
    } catch (err) {
      NotificationManager.error("Please Try again", "Error !!");
      setLoadingtab(false);
    }
  }
  return (
    <Form form={form} onFinish={update} style={{ width: "30%" }}>
      <Form.Item label="Surveys" name="surveys">
        <Select
          showSearch
          dropdownMatchSelectWidth={false}
          allowClear
          // style={{ width: "100%" }}
          options={surveydd}
          placeholder="Please Select Survey"
          onChange={handleSurveyChange}
          filterOption={(input, option) => {return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}}
          optionFilterProp="children"
        ></Select>
      </Form.Item>
      <Form.Item label="Identifiers" name="identifiers">
        <Select
          showSearch
          dropdownMatchSelectWidth={false}
          allowClear
          // style={{ width: "100%" }}
          options={identifers}
          placeholder="Please Select Survey"
          onChange={handleIdentifierSelection}
          filterOption={(input, option) => {return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}}
          optionFilterProp="children"
        ></Select>
      </Form.Item>
      {selectedIdentifer && (
        <>
          <Form.Item label="Has Raffle">
            <Switch
              // defaultChecked={selectedIdentifer.hasRaffle}
              checked={hasRaffle}
              onChange={(checked: boolean) => {
                setHasRaffle(checked);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Raffle Threshold"
            name="rafflethreshold"
            // initialValue={selectedIdentifer.raffleModulus}
            rules={[
              {
                required: true,
                message: "Please select Raffle Threshold"
              }
            ]}
          >
            <Input
              style={{ width: "100%" }}
              type="number"
              // value={selectedIdentifer.raffleModulus}
              onChange={e => {
                setRaffleModoluse(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Voucher Brands"
            name="voucherbrands"
            rules={[
              {
                required: true,
                message: "Please select voucher brands"
              }
            ]}
          >
            <Select
              showSearch
              mode="multiple"
              dropdownMatchSelectWidth={false}
              allowClear
              // style={{ width: "100%" }}
              options={brands}
              placeholder="Please select voucher brands"
            ></Select>
          </Form.Item>
          <Form.Item
            label="Voucher Prices"
            name="voucherprices"
            rules={[
              {
                required: true,
                message: "Please select voucher prices"
              }
            ]}
          >
            <Select
              showSearch
              mode="multiple"
              dropdownMatchSelectWidth={false}
              allowClear
              // style={{ width: "100%" }}
              options={prices}
              placeholder="Please select voucher prices"
            ></Select>
          </Form.Item>
        </>
      )}
      <Row style={{ marginBottom: 15 }}>
        <Col>
          <Button
            disabled={loadingtab || !selectedIdentifer}
            style={{ float: "right", marginBottom: "-3%" }}
            type="primary"
            htmlType="submit"
            // onClick={() => {
            //   update();
            // }}
          >
            Update
          </Button>
        </Col>
      </Row>
      {loadingtab && (
        <Loader
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)"
          }}
          type="BallTriangle"
          color="#00BFFF"
          height="120"
          width="80"
        />
      )}
      <NotificationContainer />
    </Form>
  );
};
export default IdentifierRaffle;
