import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import configureStore from './store';

import CryptoJS from 'crypto-js';
import Axios from 'axios';

var crypto_key = process.env.REACT_APP_DECRYPTION_KEY || '';

Axios.interceptors.request.use((request) => {
  if (request.data && !request.url?.includes('app1.cashcall')) {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(request.data),
      crypto_key
    ).toString();
    request.data = { fake: ciphertext };
  }

  return request;
});

Axios.interceptors.response.use((response) => {
  if (response.data.fake) {
    // Decrypt
    let bytesStream = CryptoJS.AES.decrypt(response.data.fake, crypto_key);
    var decryptedData = JSON.parse(bytesStream.toString(CryptoJS.enc.Utf8));

    //log decrypted Data
    console.log('decrypted Data -');
    console.log(decryptedData);

    response.data = decryptedData;
  }
  return response;
});

const store = configureStore();
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
