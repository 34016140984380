import React, { useState, useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AppLayout from '../components/layout/layout';
import Tabulation from '../components/internalTool/tabulation';
import ChangeUserStatus from '../components/internalTool/changeUserStatus';
import ChangeSubmitDate from '../components/internalTool/changeSubmitDate';
import ExportRawData from '../components/internalTool/exportRawData';
import ImportAnswers from '../components/internalTool/importAnswers';
import RemoveAnswers from '../components/internalTool/removeAnswers';
import ModifyUsers from '../components/internalTool/modifyUsers';
import GetQuota from '../components/internalTool/quota';
import Vouchers from '../components/internalTool/vouchers';
import OnDemandQC from '../components/internalTool/OnDemandQc';
import PublishData from '../components/internalTool/publishData';
import Reports from '../components/internalTool/reports';
import OpenEndedCoding from '../components/internalTool/openEndedCoding';
import IdentifierRaffle from '../components/internalTool/identifiersRaffle';
import 'react-tabs/style/react-tabs.css';
import axios from 'axios';
import {
  Cascader,
  Row,
  List,
  Col,
  Input,
  Form,
  Button,
  Typography,
  Table,
  Space,
  Card,
  Tooltip,
  Modal,
  Pagination,
  Select,
  Popover,
} from 'antd';
import Loader from 'react-loader-spinner';

const InternalTools: React.FC = () => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('logged') || 'false')
  );

  const API_URL = process.env.REACT_APP_API_URL;

  const loggedIn = localStorage.getItem('logged') ? true : false;
  const [tabIndex, setTabIndex] = useState(0);
  const [surveys, setSurveys] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSurvey = async () => {
      const response = await axios.get(`${API_URL}/internaltool/allsurveys`);
      setSurveys(response);
      setLoading(false);
    };
    fetchSurvey();
  }, []);

  return (
    <div>
      {loggedIn && (
        <AppLayout>
          {!loading && (
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index) => setTabIndex(index)}
            >
              <TabList>
                <Tab>Tabulation</Tab>
                <Tab>Export Raw Data</Tab>
                <Tab>Change Respondent Status</Tab>
                <Tab>Import Answers</Tab>
                <Tab>Remove Answers</Tab>
                <Tab>Modify Users</Tab>
                <Tab>Quotas</Tab>
                <Tab>Vouchers</Tab>
                <Tab>On Demand QC</Tab>
                <Tab>Publish Data</Tab>
                <Tab>Reports</Tab>
                <Tab>Open Ended Coding</Tab>
                <Tab>Identifiers Raffle</Tab>
                <Tab>Change Submit Date</Tab>
              </TabList>
              <TabPanel>
                <Tabulation surveys={surveys} />
              </TabPanel>
              <TabPanel>
                <ExportRawData surveys={surveys} />
              </TabPanel>
              <TabPanel>
                <ChangeUserStatus surveys={surveys} />
              </TabPanel>
              <TabPanel>
                <ImportAnswers surveys={surveys} />
              </TabPanel>
              <TabPanel>
                <RemoveAnswers surveys={surveys} />
              </TabPanel>
              <TabPanel>
                <ModifyUsers surveys={surveys} />
              </TabPanel>
              <TabPanel>
                <GetQuota surveys={surveys} />
              </TabPanel>
              <TabPanel>
                <Vouchers />
              </TabPanel>
              <TabPanel>
                <OnDemandQC surveys={surveys} />
              </TabPanel>
              <TabPanel>
                <PublishData surveys={surveys} />
              </TabPanel>
              <TabPanel>
                <Reports />
              </TabPanel>
              <TabPanel>
                <OpenEndedCoding surveys={surveys} />
              </TabPanel>
              <TabPanel>
                <IdentifierRaffle surveys={surveys} />
              </TabPanel>
              <TabPanel>
                <ChangeSubmitDate surveys={surveys} />
              </TabPanel>
            </Tabs>
          )}
          {loading && (
            <Loader
              style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }}
              type="BallTriangle"
              color="#00BFFF"
              height="120"
              width="80"
            />
          )}
        </AppLayout>
      )}
    </div>
  );
};
export default InternalTools;
