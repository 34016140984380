import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import {
  Cascader,
  Row,
  List,
  Col,
  Input,
  Form,
  Button,
  Typography,
  Table,
  Space,
  Card,
  Tooltip,
  Modal,
  Pagination,
  Select,
  Popover
} from "antd";
import Loader from "react-loader-spinner";
// @ts-ignore
import {
  NotificationContainer,
  NotificationManager
  // @ts-ignore
} from "react-notifications";
import "react-notifications/lib/notifications.css";
// @ts-ignore
import readXlsxFile from "read-excel-file";
type ModifyUsersComponentProps = {
  surveys: any;
};
const ModifyUsers: React.FC<ModifyUsersComponentProps> = props => {
  const [surveys, setSurveys] = useState<any>([]);
  const [surveydd, setSurveydd] = useState<any>([]);
  const [selectedForce, setSelectedForce] = useState<any>();
  const [selectedSurvey, setSelectedSurvey] = useState<any>();
  const [selectedStatus, setSelectedStatus] = useState<any>();
  const [questions, setQuestions] = useState<any>([]);
  const [respondentId, setRespondentId] = useState("");
  const [importedData, setImportedData] = useState<any>([]);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [loadingtab, setLoadingtab] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  var FileSaver = require("file-saver");
  useEffect(() => {
    const fetchSurvey = async () => {
      setSurveys(props.surveys);
      if (
        props.surveys &&
        props.surveys.data &&
        props.surveys.data.length > 0
      ) {
        setSurveydd(
          props.surveys.data.map((survey: any) => {
            return { value: survey.id, label: survey.name };
          })
        );
      }
    };
    fetchSurvey();
  }, [props.surveys]);
  function handleSurveyChange(value: any) {
    const questionsTemp: any = [];
    surveys.data.map((survey: any) => {
      if (survey.id === value) {
        setSelectedSurvey(survey);
      }
    });
    setQuestions(questionsTemp);
  }
  function handleStatusChange(value: any) {
    setSelectedStatus(value);
  }
  function handleForceChange(value: any) {
    setSelectedForce(!selectedForce);
  }
  async function parseData(e: any) {
    console.log("Parse data");
    console.log(e.target.files);
    setLoadingtab(true);
    let tempImportedData: any = [];
    await readXlsxFile(e.target.files[0]).then((rows: any) => {
      for (let i = 0; i < rows.length; i++) {
        let sessionId = rows[i][0];
        let reason = rows[i][1];
        tempImportedData.push({ sessionId: sessionId, reason: reason });
      }
    });
    setImportedData(tempImportedData);
    setLoadingtab(false);
    setDisableSubmit(false);
  }
  async function excludeRespondent() {
    try {
      const requestBody = {
        userData: importedData,
        surveyId: selectedSurvey.id,
        force: selectedForce,
        user: localStorage.getItem('username')

      };

      await axios
        .post(API_URL + "/internaltool/exclude-user", requestBody)
        .then(response => {
          NotificationManager.success("User Excluded", "Success");
        })
        .catch(err => {
          NotificationManager.error("Please Try again", "Error !!");
        });
    } catch (err) {
      NotificationManager.error("Please Try again", "Error !!");
    }
  }
  async function includeRespondent() {
    const requestBody = {
      userData: importedData,
      surveyId: selectedSurvey.id,
      force: selectedForce,
      user: localStorage.getItem('username')

    };
    try {
      await axios
        .post(API_URL + "/internaltool/include-user", requestBody, {
          responseType: "arraybuffer"
        })
        .then(response => {
          console.log(response);
          var blob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          FileSaver.saveAs(
            blob,
            `includeRespondents_${selectedSurvey.id}_${new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '')}}`
          );
        });
      setLoadingtab(false);
      NotificationManager.success(
        "File Downloaded Succesfully",
        "Success !!",
        3000
      );
    } catch (err) {
      setLoadingtab(false);
      NotificationManager.error("Please try again", "Error !!", 3000);
    }
  }
  return (
    <Form>
      <Row style={{ width: "100%", marginBottom: 15 }}>
        <Col span={4}>Survey:</Col>
        <Col span={4}>
          <Select
            showSearch
            dropdownMatchSelectWidth={false}
            allowClear
            style={{ width: "100%" }}
            options={surveydd}
            placeholder="Please Select Survey"
            onChange={handleSurveyChange}
            filterOption={(input, option) => {return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}}
            optionFilterProp="children"
          ></Select>
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Col span={4}>Upload Respondents:</Col>
        <Col span={4}>
          <Input
            style={{ width: "100%" }}
            type="file"
            onChange={e => {
              parseData(e);
            }}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Col span={4}>Force:</Col>
        <Col span={4}>
          <Input
            name="force"
            type="checkbox"
            value={selectedForce}
            onChange={handleForceChange} />
        </Col>
      </Row>
      <Space>
        <Col>
          <Button
            disabled={loadingtab || disableSubmit}
            style={{ float: "right", marginBottom: "-3%" }}
            onClick={() => {
              excludeRespondent();
            }}
          >
            Exclude
          </Button>
        </Col>
        &nbsp;
        <Col>
          <Button
            disabled={loadingtab || disableSubmit}
            style={{ float: "right", marginBottom: "-3%" }}
            onClick={() => {
              includeRespondent();
            }}
          >
            Include
          </Button>
        </Col>
      </Space>
      <NotificationContainer />
    </Form>
  );
};
export default ModifyUsers;
