import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import {
  Cascader,
  Row,
  List,
  Col,
  Input,
  Form,
  Button,
  Typography,
  Table,
  Space,
  Card,
  Tooltip,
  Modal,
  Pagination,
  Select,
  Popover
} from "antd";
import Loader from "react-loader-spinner";
// @ts-ignore
import {
  NotificationContainer,
  NotificationManager
  // @ts-ignore
} from "react-notifications";
import "react-notifications/lib/notifications.css";
type OnDemandComponentProps = {
  surveys: any;
};
const OnDemandQC: React.FC<OnDemandComponentProps> = props => {
  const [surveys, setSurveys] = useState<any>([]);
  const [surveydd, setSurveydd] = useState<any>([]);
  const [selectedSurvey, setSelectedSurvey] = useState<any>();
  const [questions, setQuestions] = useState<any>([]);
  const API_URL = process.env.REACT_APP_API_URL;
  const [loadingtab, setLoadingtab] = useState(false);
  const [qcrules, setQcrules] = useState<any>([]);
  const [selectedqcrules, setSelectedqcrules] = useState<any>([]);
  useEffect(() => {
    const fetchSurvey = async () => {
      setSurveys(props.surveys);
      if (
        props.surveys &&
        props.surveys.data &&
        props.surveys.data.length > 0
      ) {
        setSurveydd(
          props.surveys.data.map((survey: any) => {
            return { value: survey.id, label: survey.name };
          })
        );
      }
    };
    fetchSurvey();
  }, [props.surveys]);

  async function handleSurveyChange(value: any) {
    if (value) {
      setSelectedSurvey(value);
      await axios
        .get(API_URL + `/internaltool/getSurveyQCRules?surveyId=${value}`)
        .then(response => {
          let rules = [];
          if (response.data.rules && response.data.rules.length > 0) {
            for (let i = 0; i < response.data.rules.length; i++) {
              rules.push({
                value: response.data.rules[i].id,
                label: response.data.rules[i].description
              });
            }
            setQcrules(rules);
          } else {
            console.log("EMPTY RULE");
            setQcrules(null);
            setSelectedqcrules(null);
          }
        });
    }
  }
  async function handleRuleChange(value: any) {
    setSelectedqcrules(value);
  }
  async function runOnDemandQC() {
    setLoadingtab(true);
    try {
      const requestBody = {
        surveyId: selectedSurvey,
        ruleIds: selectedqcrules,
        action: "act"
      };
      try {
        await axios
          .post(API_URL + "/internaltool/ondemand-qc", requestBody)
          .then(response => {
            setLoadingtab(false);
            NotificationManager.success(
              "OnDemand QC triggered successfully",
              "Success !!",
              3000
            );
          });
      } catch (err) {
        setLoadingtab(false);
        NotificationManager.error("Please try again", "Error !!", 3000);
      }
    } catch (err) {
      setLoadingtab(false);
      NotificationManager.error("Please Try again", "Error !!");
    }
  }
  return (
    <Form>
      <Row style={{ width: "100%", marginBottom: 15 }}>
        <Col span={4}>Survey:</Col>
        <Col span={4}>
          <Select
            showSearch
            dropdownMatchSelectWidth={false}
            allowClear
            style={{ width: "100%" }}
            options={surveydd}
            placeholder="Please Select Survey"
            onChange={handleSurveyChange}
            filterOption={(input, option) => {return (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}}
            optionFilterProp="children"
          ></Select>
        </Col>
      </Row>
      <Row style={{ width: "100%", marginBottom: 15 }}>
        <Col span={4}>Rules:</Col>
        <Col span={4}>
          <Select
            mode="multiple"
            dropdownMatchSelectWidth={false}
            allowClear={true}
            style={{ width: "100%" }}
            options={qcrules}
            placeholder="Please Select Rules"
            onChange={handleRuleChange}
            disabled={!qcrules || (qcrules && qcrules.length === 0)}
          ></Select>
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Button
          disabled={loadingtab || !qcrules || (qcrules && qcrules.length === 0)}
          style={{ float: "right", marginBottom: "-3%" }}
          onClick={() => {
            runOnDemandQC();
          }}
        >
          Submit
        </Button>
      </Row>
      {loadingtab && (
        <Loader
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)"
          }}
          type="BallTriangle"
          color="#00BFFF"
          height="120"
          width="80"
        />
      )}
      <NotificationContainer />
    </Form>
  );
};
export default OnDemandQC;
